import * as yup from 'yup'
import FieldNames from '../fields'
import { email, password } from './common'

const schemaSpec = {
  [FieldNames.EMAIL]: email(),
  [FieldNames.PASSWORD]: password(),
}

export default function validationSchema() {
  return yup.object(schemaSpec)
}
