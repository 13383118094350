/**
 * Entry application component used to compose providers and render Routes.
 **/

import { hot } from 'react-hot-loader/root'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { LastLocationProvider } from 'react-router-last-location'
import { Routes } from './app/Routes'
import { I18nProvider } from './_metronic/i18n'
import { LayoutSplashScreen, MaterialThemeProvider } from './_metronic/layout'
import PropTypes from 'prop-types'
import { SnackbarProvider } from 'notistack'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { ErrorPage6 } from './app/modules/ErrorsExamples/ErrorPage6'

const App = function ({ store, persistor, basename }) {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage6} showDialog>
      <Helmet
        titleTemplate="%s - TradingSignally"
        defaultTitle="TradingSignally"
      />
      {/* Provide Redux store */}
      <Provider store={store} loading={<LayoutSplashScreen />}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <LastLocationProvider>
                {/* Provide Metronic theme overrides. */}
                <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/*notistack snackbar provider*/}
                    <SnackbarProvider maxSnack={5}>
                      {/* Render routes with provided `Layout`. */}
                      <Routes />
                    </SnackbarProvider>
                  </I18nProvider>
                </MaterialThemeProvider>
              </LastLocationProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  persistor: PropTypes.object.isRequired,
  basename: PropTypes.string.isRequired,
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
