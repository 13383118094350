import {
  all,
  call,
  fork,
  put,
  take,
  takeLatest,
  cancel,
} from 'redux-saga/effects'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import firebase, { analytics } from '../../services/firebase'
import ReduxSagaFirebase from 'redux-saga-firebase'
import {
  AuthHelpers,
  FirestoreHelpers,
  Helpers,
  ReduxSetters,
} from '@tad3j/shared'
import { forgotLastLocation } from '../../_metronic/_helpers'
import { actions as signalsActions } from './signals.duck'
import { actions as signalGroupsActions } from './signalGroups.duck'

const rsf = new ReduxSagaFirebase(firebase)

export const actionTypes = {
  AUTH: {
    REGISTER_REQUEST: 'AUTH.REGISTER_REQUEST',
    REGISTER_SUCCESS: 'AUTH.REGISTER_SUCCESS',
    REGISTER_FAILURE: 'AUTH.REGISTER_FAILURE',
    LOGIN_REQUEST: 'AUTH.LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH.LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH.LOGIN_FAILURE',
    USER_DATA_STARTED: 'AUTH.USER_DATA_STARTED',
    USER_DATA_COMPLETE: 'AUTH.USER_DATA_COMPLETE',
    USER_DETAILS_SUCCESS: 'AUTH.USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'AUTH.USER_DETAILS_FAILURE',
    USER_CLAIMS_SUCCESS: 'AUTH.USER_CLAIMS_SUCCESS',
    USER_SECRETS_SUCCESS: 'AUTH.USER_SECRETS_SUCCESS',
    USER_SECRETS_FAILURE: 'AUTH.USER_SECRETS_FAILURE',
    LOGOUT_REQUEST: 'AUTH.LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTH.LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH.LOGOUT_FAILURE',
    SEND_PASSWORD_RESET_EMAIL_REQUEST: 'AUTH.SEND_PASSWORD_RESET_EMAIL_REQUEST',
    SEND_PASSWORD_RESET_EMAIL_SUCCESS: 'AUTH.SEND_PASSWORD_RESET_EMAIL_SUCCESS',
    SEND_PASSWORD_RESET_EMAIL_FAILURE: 'AUTH.SEND_PASSWORD_RESET_EMAIL_FAILURE',
    REQUEST_PUBLISHER_ROLE: 'AUTH.REQUEST_PUBLISHER_ROLE',
    REQUEST_PUBLISHER_ROLE_SUCCESS: 'AUTH.REQUEST_PUBLISHER_ROLE_SUCCESS',
    REQUEST_PUBLISHER_ROLE_FAILURE: 'AUTH.REQUEST_PUBLISHER_ROLE_FAILURE',
    // REQUEST_PRIVATE_GROUP_ACCESS: 'AUTH.REQUEST_PRIVATE_GROUP_ACCESS',
    // REVOKE_PRIVATE_GROUP_MEMBERSHIP: 'AUTH.REVOKE_PRIVATE_GROUP_MEMBERSHIP',
    // SUBSCRIBE_USER: 'AUTH.SUBSCRIBE_USER',
    // UNSUBSCRIBE_USER: 'AUTH.UNSUBSCRIBE_USER',
  },
}

export const authActionTypes = actionTypes.AUTH

const initialAuthState = {
  //loading must be true on start (for example, if user is already logged in, initial state must be loading,
  // since LOGIN_SUCCESS is fired every time, and we reload userDetails)
  loading: true,
  loggedIn: false,
  user: null,
  userDetails: {},
  userSecrets: {},
  devices: {},
}

export const reducer = persistReducer(
  {
    storage,
    key: 'trading-signally-auth',
    whitelist: ['user', 'userDetails', 'userSecrets', 'loggedIn'],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      //REGISTER
      case authActionTypes.REGISTER_SUCCESS:
        return ReduxSetters.Auth.setRegisterSuccess(
          action.uid,
          action.email,
          action.username,
          firebase.firestore.Timestamp.now().toMillis()
        )(state)
      //LOGIN
      case authActionTypes.LOGIN_SUCCESS:
        return {
          ...state,
          loggedIn: true,
          loading: true,
          user: action.user,
        }
      case authActionTypes.LOGIN_FAILURE:
        return {
          ...state,
          loading: false,
        }
      case authActionTypes.USER_DATA_COMPLETE:
        return {
          ...state,
          loading: false,
        }
      case authActionTypes.USER_CLAIMS_SUCCESS:
        return {
          ...state,
          isAdmin: action.claims.isAdmin,
          isPublisher: action.claims.isPublisher,
        }
      case authActionTypes.USER_DETAILS_SUCCESS:
        return Object.keys(action.userDetails).length > 1
          ? {
              ...state,
              userDetails: action.userDetails,
            }
          : state
      case authActionTypes.USER_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        }
      case authActionTypes.USER_SECRETS_SUCCESS:
        return Object.keys(action.userSecrets).length > 1
          ? {
              ...state,
              userSecrets: action.userSecrets,
            }
          : state
      case authActionTypes.USER_SECRETS_FAILURE:
        return {
          ...state,
          loading: false,
        }
      //LOGOUT
      case authActionTypes.LOGOUT_REQUEST:
        forgotLastLocation()
        return {
          ...state,
          loading: true,
        }
      case authActionTypes.LOGOUT_SUCCESS:
        return { ...initialAuthState, loading: false }
      case authActionTypes.LOGOUT_FAILURE:
        return {
          ...state,
          loading: false,
        }
      case authActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      case authActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE:
        return {
          ...state,
          loading: false,
        }
      case authActionTypes.REQUEST_PUBLISHER_ROLE_SUCCESS:
        return {
          ...state,
          accessRequested: true,
        }
      // //membership
      // case actionTypes.AUTH.REQUEST_PRIVATE_GROUP_ACCESS:
      //   return update(
      //     'userDetails.privateGroupRequests',
      //     (requests) => {
      //       if (!requests.includes(action.gid)) {
      //         requests.push(action.gid)
      //       }
      //       return requests
      //     },
      //     state
      //   )
      // case authActionTypes.REVOKE_PRIVATE_GROUP_MEMBERSHIP:
      //   return Helpers.filterArrayItems(
      //     'userDetails.privateGroupRequests',
      //     action.gid,
      //     Helpers.filterArrayItems(
      //       'userDetails.privateGroupMembership',
      //       action.gid,
      //       state
      //     )
      //   )
      // case authActionTypes.SUBSCRIBE_USER:
      //   return update(
      //     'userDetails.signalGroupsFollowing',
      //     (sgf) => {
      //       if (!sgf.includes(action.gid)) {
      //         sgf.push(action.gid)
      //       }
      //       return sgf
      //     },
      //     state
      //   )
      // case authActionTypes.UNSUBSCRIBE_USER:
      //   return Helpers.filterArrayItems(
      //     'userDetails.signalGroupsFollowing',
      //     action.gid,
      //     state
      //   )
      default:
        return state
    }
  }
)

export const actions = {
  register: (values, onSuccess, onError) => ({
    type: authActionTypes.REGISTER_REQUEST,
    values,
    onSuccess,
    onError,
  }),
  registerSuccess: (uid, email, username) => ({
    type: authActionTypes.REGISTER_SUCCESS,
    uid,
    email,
    username,
  }),
  registerFailure: (error) => ({
    type: authActionTypes.REGISTER_FAILURE,
    error,
  }),
  login: (values, onSuccess, onError) => ({
    type: authActionTypes.LOGIN_REQUEST,
    values,
    onSuccess,
    onError,
  }),
  loginFailure: (error) => ({ type: authActionTypes.LOGIN_FAILURE, error }),
  userDataStarted: (user) => ({
    type: authActionTypes.USER_DATA_STARTED,
    user,
  }),
  userDataComplete: () => ({
    type: authActionTypes.USER_DATA_COMPLETE,
  }),
  loginSuccess: (user) => ({ type: authActionTypes.LOGIN_SUCCESS, user }),
  getUserClaimsSuccess: (claims) => ({
    type: authActionTypes.USER_CLAIMS_SUCCESS,
    claims,
  }),
  getUserDetailsSuccess: (userDetails) => ({
    type: authActionTypes.USER_DETAILS_SUCCESS,
    userDetails,
  }),
  getUserDetailsFailure: (error) => ({
    type: authActionTypes.USER_DETAILS_FAILURE,
    error,
  }),
  getUserSecretsSuccess: (userSecrets) => ({
    type: authActionTypes.USER_SECRETS_SUCCESS,
    userSecrets,
  }),
  getUserSecretsFailure: (error) => ({
    type: authActionTypes.USER_SECRETS_FAILURE,
    error,
  }),
  logout: () => ({ type: authActionTypes.LOGOUT_REQUEST }),
  logoutSuccess: () => ({ type: authActionTypes.LOGOUT_SUCCESS }),
  logoutFailure: (error) => ({ type: authActionTypes.LOGOUT_FAILURE, error }),
  sendPasswordResetEmail: (values, onSuccess, onError) => ({
    type: authActionTypes.SEND_PASSWORD_RESET_EMAIL_REQUEST,
    values,
    onSuccess,
    onError,
  }),
  sendPasswordResetEmailSuccess: () => ({
    type: authActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
  }),
  sendPasswordResetEmailFailure: (error) => ({
    type: authActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE,
    error,
  }),
  requestPublisherAccess: (values, uid, onSuccess, onError) => ({
    type: authActionTypes.REQUEST_PUBLISHER_ROLE,
    values,
    uid,
    onSuccess,
    onError,
  }),
  requestPublisherAccessSuccess: () => ({
    type: authActionTypes.REQUEST_PUBLISHER_ROLE_SUCCESS,
  }),
  requestPublisherAccessFailure: () => ({
    type: authActionTypes.REQUEST_PUBLISHER_ROLE_FAILURE,
  }),
  // requestPrivateGroupMembership: (gid) => ({
  //   type: authActionTypes.REQUEST_PRIVATE_GROUP_ACCESS,
  //   gid,
  // }),
  // revokePrivateGroupMembership: (gid) => ({
  //   type: authActionTypes.REVOKE_PRIVATE_GROUP_MEMBERSHIP,
  //   gid,
  // }),
  // subscribeUser: (gid) => ({
  //   type: authActionTypes.SUBSCRIBE_USER,
  //   gid,
  // }),
  // unsubscribeUser: (gid) => ({
  //   type: authActionTypes.UNSUBSCRIBE_USER,
  //   gid,
  // }),
}

function* registerSaga(action) {
  const values = action.values
  try {
    //create user in firebase auth
    const result = yield call(
      rsf.auth.createUserWithEmailAndPassword,
      values.email,
      values.password
    )
    //create user in firestore DB
    const user = result.user
    const email = user.email
    const username = Helpers.getUserNameFromEmail(email)
    //success for redux
    yield put(actions.registerSuccess(user.uid, email, username))
    //success for UI
    action.onSuccess()
  } catch (error) {
    //try to handle exception and if cant, let's dispatch action to the store
    if (!AuthHelpers.handleException(error, action.onError)) {
      yield put(actions.registerFailure(error))
      console.error(error)
    }
  }
}

// function* sendPasswordResetEmailSaga(action) {
//   console.log(action);
//   try {
//     yield call(rsf.auth.sendPasswordResetEmail, action.email);
//     yield put(actions.sendPasswordResetEmailSuccess());
//   }
//   catch(error) {
//     yield put(actions.sendPasswordResetEmailFailure(error));
//   }
// }

function* loginSaga(action) {
  const values = action.values
  try {
    yield call(
      rsf.auth.signInWithEmailAndPassword,
      values.email,
      values.password
    )
    yield analytics.logEvent('login', { method: 'email' })
    action.onSuccess()
    // successful login will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    //try to handle exception and if cant, let's dispatch action to the store
    if (!AuthHelpers.handleException(error, action.onError)) {
      yield put(actions.loginFailure(error))
      console.error(error)
    }
  }
}

function* loginStatusWatcher() {
  // events on this channel fire when the user logs in or logs out
  const channel = yield call(rsf.auth.channel)
  while (true) {
    const { user } = yield take(channel)
    if (user) {
      yield put(actions.loginSuccess(user))
      analytics.setUserId(user.uid)
    } else {
      yield put(actions.logoutSuccess())
      analytics.setUserId(null)
    }
  }
}

async function awaitTokenResult(user) {
  return await user.getIdTokenResult()
}

function* userClaimsRequestSaga(action) {
  const idTokenResult = yield call(awaitTokenResult, action.user)
  yield put(actions.getUserClaimsSuccess(idTokenResult.claims))
}

function* userDetailsSyncSaga(action) {
  const uid = action.user.uid
  // Start the sync saga
  const syncUserDetailsTask = yield fork(
    rsf.firestore.syncDocument,
    `users/${uid}`,
    {
      transform: FirestoreHelpers.receiveUser,
      successActionCreator: actions.getUserDetailsSuccess,
      failureActionCreator: actions.getUserDetailsFailure,
    }
  )

  // Wait for the logout action, then stop sync
  yield take(authActionTypes.LOGOUT_SUCCESS)

  //cancel sync task
  yield cancel(syncUserDetailsTask)
}

function* userSecretsSyncSaga(action) {
  const uid = action.user.uid
  // Start the sync saga
  const syncUserSecretsTask = yield fork(
    rsf.firestore.syncDocument,
    `userSecrets/${uid}`,
    {
      transform: FirestoreHelpers.receiveUserSecrets,
      successActionCreator: actions.getUserSecretsSuccess,
      failureActionCreator: actions.getUserSecretsFailure,
    }
  )

  // Wait for the logout action, then stop sync
  yield take(authActionTypes.LOGOUT_SUCCESS)

  //cancel sync task
  yield cancel(syncUserSecretsTask)
}

function* logoutSaga() {
  try {
    yield call(rsf.auth.signOut)
    analytics.logEvent('logout')
    // successful logout will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    yield put(actions.logoutFailure(error))
  }
}

function* sendPasswordResetEmailSaga(action) {
  const values = action.values
  try {
    yield call(rsf.auth.sendPasswordResetEmail, values.email)
    yield put(actions.sendPasswordResetEmailSuccess())
    action.onSuccess()
  } catch (error) {
    //try to handle exception and if cant, let's dispatch action to the store
    if (!AuthHelpers.handleException(error, action.onError, true)) {
      yield put(actions.sendPasswordResetEmailFailure(error))
      console.error(error)
    }
  }
}

function* userDataCompleteSaga(action) {
  yield put(actions.userDataStarted(action.user))
  yield all([
    take(authActionTypes.USER_SECRETS_SUCCESS),
    take(authActionTypes.USER_CLAIMS_SUCCESS),
    take(authActionTypes.USER_DETAILS_SUCCESS),
  ])
  yield put(actions.userDataComplete())
}
function* requestPublisherRoleSaga(action) {
  const publisherRequest = action.values
  //delete empty object
  if (publisherRequest.existingGroup === 'yes') {
    delete publisherRequest.person
  } else {
    delete publisherRequest.group
  }
  try {
    yield call(
      rsf.firestore.setDocument,
      `users/${action.uid}`,
      { publisherRequest },
      {
        merge: true,
      }
    )
    yield put(actions.requestPublisherAccessSuccess())
    action.onSuccess()
  } catch (error) {
    //try to handle exception and if cant, let's dispatch action to the store
    if (!AuthHelpers.handleException(error, action.onError, true)) {
      yield put(actions.requestPublisherAccessFailure(error))
      console.error(error)
    }
  }
}

function* triggerAuthDependantActionsSaga(/*action*/) {
  // //re-sync related
  yield put(signalGroupsActions.syncSignalGroupsRequest())
  yield put(signalsActions.syncSignalsRequest())
}

/**
 * ANALYTICS
 */
const logRequestPublisherRoleSuccess = (/*action*/) =>
  analytics.logEvent('request_publisher_access')
const logSignUpSuccess = () =>
  analytics.logEvent('sign_up', { method: 'email' })

export function* saga() {
  yield fork(loginStatusWatcher)
  yield all([
    //register
    takeLatest(authActionTypes.REGISTER_REQUEST, registerSaga),
    takeLatest(authActionTypes.REGISTER_SUCCESS, logSignUpSuccess),
    //login
    takeLatest(authActionTypes.LOGIN_REQUEST, loginSaga),
    //user data
    takeLatest(authActionTypes.LOGIN_SUCCESS, userDataCompleteSaga),
    takeLatest(authActionTypes.USER_DATA_STARTED, userDetailsSyncSaga),
    takeLatest(authActionTypes.USER_DATA_STARTED, userSecretsSyncSaga),
    takeLatest(authActionTypes.USER_DATA_STARTED, userClaimsRequestSaga),
    takeLatest(
      authActionTypes.USER_DETAILS_SUCCESS,
      triggerAuthDependantActionsSaga
    ),
    //logout
    takeLatest(authActionTypes.LOGOUT_REQUEST, logoutSaga),
    takeLatest(
      authActionTypes.SEND_PASSWORD_RESET_EMAIL_REQUEST,
      sendPasswordResetEmailSaga
    ),
    takeLatest(
      authActionTypes.REQUEST_PUBLISHER_ROLE,
      requestPublisherRoleSaga
    ),
    takeLatest(
      authActionTypes.REQUEST_PUBLISHER_ROLE_SUCCESS,
      logRequestPublisherRoleSuccess
    ),
  ])
}
