import sanitizeHtml from 'sanitize-html'

/**
 * Sanitization with https://www.npmjs.com/package/sanitize-html
 * @param {string} html
 * @param {boolean} allowParagraph (as a workaround we are allowing paragraphs for UI side to work properly, we remove
 * paragraphs when posting a message inside createTelegramPostConnector - only place where we pass in false)
 * @return {string}
 */
const telegramMarkupSanitization = (html, allowParagraph = true) => {
  const allowedTags = [
    'a',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'ins',
    's',
    'strike',
    'code',
    'pre',
  ]
  if (allowParagraph) {
    allowedTags.push('p')
  }
  return sanitizeHtml(html, {
    allowedTags,
    allowedAttributes: {
      a: ['href'],
    },
  })
}

export default telegramMarkupSanitization
