import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const TelegramIcon = ({ className, ...props }) => {
  return <span {...props} className={clsx('socicon-telegram', className)} />
}
TelegramIcon.propTypes = {
  className: PropTypes.string,
}

export default TelegramIcon
