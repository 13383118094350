const DOMAIN_NAME = 'tradingsignally.com'
const WEBSITE_URL = 'https://tradingsignally.com'
const EMAIL = 'support@tradingsignally.com'
const MOBILE_URI_SCHEME = 'tradingsignally'
const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.tradingsignally'

export default {
  DOMAIN_NAME,
  WEBSITE_URL,
  EMAIL,
  MOBILE_URI_SCHEME,
  GOOGLE_PLAY_URL,
}
