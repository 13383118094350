import * as yup from 'yup'

const MIN_PASSWORD_LEN = 6

export const password = () => yup.string().trim().required() //intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
export const passwordMinLen = () => password().min(MIN_PASSWORD_LEN)
export const confirmPasswordMinLen = () =>
  passwordMinLen().oneOf(
    [yup.ref('password'), null],
    "Password and Confirm Password didn't match."
  )

export const email = () =>
  yup
    .string()
    .trim()
    .required()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      'Should be a valid email address'
    )

export const displayName = () => yup.string().trim().required().min(4)
