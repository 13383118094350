import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from './ducks/auth.duck'
import * as signals from './ducks/signals.duck'
import * as signalGroups from './ducks/signalGroups.duck'
import * as users from './ducks/users.duck'
import * as integrations from './ducks/integrations.duck'

export const rootReducer = combineReducers({
  signalGroups: signalGroups.reducer,
  signals: signals.reducer,
  auth: auth.reducer,
  users: users.reducer,
  integrations: integrations.reducer,
})

export function* rootSaga() {
  yield all([
    auth.saga(),
    signals.saga(),
    signalGroups.saga(),
    users.saga(),
    integrations.saga(),
  ])
}
