import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import TransitionUp from './TransitionUp'
import DialogTitleWithCloseIcon from './components/DialogTitleWithCloseIcon'

const GeneralDialog = ({
  title,
  linkText,
  children,
  className,
  ariaName,
  ...rest
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = React.useState(false)
  function handleClickOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <>
      <Link
        to="#"
        rel="noopener noreferrer"
        className={className}
        onClick={handleClickOpen}
      >
        {linkText}
      </Link>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        {...rest}
      >
        <DialogTitleWithCloseIcon dialogClose={handleClose}>
          {title}
        </DialogTitleWithCloseIcon>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

GeneralDialog.propTypes = {
  title: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  ariaName: PropTypes.string.isRequired,
}

export default GeneralDialog
