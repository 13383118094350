import * as yup from 'yup'
import FieldNames from '../fields'
import { confirmPasswordMinLen, email, passwordMinLen } from './common'

const agreeToTermsMessage = 'you need to agree to terms and conditions'
export const schemaSpec = {
  [FieldNames.EMAIL]: email(),
  [FieldNames.PASSWORD]: passwordMinLen(),
  [FieldNames.CONFIRM_PASSWORD]: confirmPasswordMinLen(),
  [FieldNames.ACCEPT_TERMS]: yup
    .boolean()
    .required(agreeToTermsMessage)
    .test('is boolean true', agreeToTermsMessage, (value) => value === true),
  // [FieldNames.USERNAME]: yup
  //   .string()
  //   .trim()
  //   .required() //intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
  //   .min(4)
  //   .max(15)
  //   .matches(
  //     /^[-_a-zA-Z0-9]+$/,
  //     'Username can only contain alphanumeric characters, underscore (_) and dash (-) symbols.'
  //   ),
}

export default function validationSchema() {
  return yup.object(schemaSpec)
}
