import React, { Component } from 'react'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import { Link, Redirect } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import * as auth from '../../../redux/ducks/auth.duck'
import { forgotPasswordValidationSchema } from '@tad3j/shared'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const style = { display: 'block' }
class ForgotPassword extends Component {
  state = { isRequested: false, loading: false }

  render() {
    const { sendPasswordResetEmail } = this.props
    const { isRequested, loading } = this.state
    const handleFormSubmit = (values, { setStatus }) => {
      this.setState({ loading: true })
      const onError = (message) => {
        this.setState({ loading: false })
        setStatus(message)
      }

      const onSuccess = () => {
        this.setState({ loading: false })
        this.setState({ isRequested: true })
      }

      //register
      sendPasswordResetEmail(values, onSuccess, onError)
    }

    if (isRequested) {
      return <Redirect to="/auth" />
    }

    return (
      <>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <div className="login-form login-forgot" style={style}>
          <div className="text-center mb-5 mb-lg-10">
            <h3 className="font-size-h1">
              <div className="text-muted font-weight-bold">
                <FormattedMessage id="AUTH.FORGOT.TITLE" />
              </div>
            </h3>
          </div>

          <Formik
            initialValues={{ email: '' }}
            validationSchema={forgotPasswordValidationSchema()}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
            }) => (
              <Form className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
                {status && (
                  <div
                    role="alert"
                    className="mb-10 alert alert-custom alert-light-danger alert-dismissible"
                  >
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group fv-plugins-icon-container">
                  <TextField
                    variant="filled"
                    type="email"
                    label="Email"
                    margin="normal"
                    fullWidth={true}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={loading}
                  >
                    Submit
                  </button>
                  <Link to="/auth">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Back to Sign in
                    </button>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    )
  }
}

ForgotPassword.propTypes = {
  sendPasswordResetEmail: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  sendPasswordResetEmail: auth.actions.sendPasswordResetEmail,
}

export default injectIntl(connect(null, mapDispatchToProps)(ForgotPassword))
