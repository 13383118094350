import * as yup from 'yup'
import { confirmPasswordMinLen, password, passwordMinLen } from './common'
import FieldNames from '../fields'

export const schemaSpec = {
  [FieldNames.ORIGINAL_PASSWORD]: password(),
  [FieldNames.PASSWORD]: passwordMinLen().notOneOf(
    [yup.ref(FieldNames.ORIGINAL_PASSWORD)],
    'New password should be different than current password.'
  ),
  [FieldNames.CONFIRM_PASSWORD]: confirmPasswordMinLen(),
}
export default function validationSchema() {
  return yup.object(schemaSpec)
}
