import React from 'react'
import PropTypes from 'prop-types'

const FormErrorAlert = (props) => {
  const { status } = props

  return status ? (
    <div role="alert" className="alert alert-danger">
      <div className="alert-text">{status}</div>
    </div>
  ) : (
    ''
  )
}

FormErrorAlert.propTypes = {
  status: PropTypes.node,
}

export default FormErrorAlert
