import React from 'react'

const ThirdPartyPrivacyLinks = () => {
  return (
    <ul>
      <li>
        <a
          href="https://www.google.com/policies/privacy/PrivacyPolicy.js"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Play Services
        </a>
      </li>
      <li>
        <a
          href="https://support.google.com/admob/answer/6128543?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          AdMob
        </a>
      </li>
      <li>
        <a
          href="https://firebase.google.com/policies/analytics"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics for Firebase
        </a>
      </li>
      <li>
        <a
          href="https://firebase.google.com/support/privacy/PrivacyPolicy.js"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firebase Crashlytics
        </a>
      </li>
      <li>
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fabric
        </a>
      </li>
      <li>
        <a
          href="https://sentry.io/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sentry
        </a>
      </li>
    </ul>
  )
}

export default ThirdPartyPrivacyLinks
