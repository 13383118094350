import React from 'react'
import { Avatar, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const AvatarTransparent = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <Avatar {...rest} classes={{ colorDefault: classes.avatarColorDefault }}>
      {children}
    </Avatar>
  )
}

const useStyles = makeStyles((/*theme*/) => ({
  avatarColorDefault: {
    backgroundColor: 'transparent',
  },
}))
AvatarTransparent.propTypes = {
  children: PropTypes.node.isRequired,
}
export default AvatarTransparent
