import React, { useMemo } from 'react'
import { useHtmlClassService } from '../..'
import GeneralDialog from '../../../../app/partials/dialogs/GeneralDialog'
import PrivacyPolicy from '../../../../app/partials/policies/PrivacyPolicy'
import TermsAndConditions from '../../../../app/partials/policies/TermsAndConditions'
import ContactFormDialog from '../../../../app/partials/dialogs/ContactFormDialog'

export function Footer() {
  const today = new Date().getFullYear()
  const uiService = useHtmlClassService()

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true),
    }
  }, [uiService])

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">
            {today.toString()}
          </span>{' '}
          &copy;{' '}
          <a
            href="http://tradingsignally.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
          >
            TradingSignally
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <GeneralDialog
            title="Privacy Policy"
            linkText="Privacy"
            className="nav-link pr-3 pl-0"
            ariaName="privacy-policy"
          >
            <PrivacyPolicy />
          </GeneralDialog>
          <GeneralDialog
            title="Terms & Conditions"
            linkText="Terms & Conditions"
            className="nav-link px-3"
            ariaName="terms-and-conditions"
          >
            <TermsAndConditions />
          </GeneralDialog>
          <ContactFormDialog
            linkText="Contact"
            className="nav-link pl-3 pr-0"
          />
        </div>
      </div>
    </div>
  )
}
