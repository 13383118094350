import Wordpress from './wordpress'
import Telegram from './telegram'
import Validators from './validators'
import Connectors from './connectors'

const IntegrationNames = {
  [Wordpress.type]: 'Wordpress',
  [Telegram.type]: 'Telegram',
}
const IntegrationTypes = Object.keys(IntegrationNames)
const getName = (type) => IntegrationNames[type]

export default {
  getName,
  Types: IntegrationTypes,
  Validators,
  Connectors,
  Wordpress,
  Telegram,
}
