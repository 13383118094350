import {
  all,
  call,
  put,
  fork,
  select,
  take,
  takeLatest,
  cancel,
} from 'redux-saga/effects'
import firebase, { db, functions } from '../../services/firebase'
import ReduxSagaFirebase from 'redux-saga-firebase'
import { FirestoreHelpers } from '@tad3j/shared'
import { authActionTypes } from './auth.duck'

const rsf = new ReduxSagaFirebase(firebase)

export const actionTypes = {
  USERS: {
    SYNC_REQUEST: 'USERS.SYNC_REQUEST',
    SYNC_SUCCESS: 'USERS.SYNC_SUCCESS',
    SYNC_FAILURE: 'USERS.SYNC_FAILURE',
    PROMOTE: 'USERS.PROMOTE',
  },
  VISITORS: {
    CONTACT_FORM_SUBMIT: 'VISITORS.CONTACT_FORM_SUBMIT',
  },
}

const initialState = {
  list: [],
  promoteUsers: null,
}

export const reducer = function (state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.USERS.SYNC_SUCCESS:
      return {
        ...state,
        list: action.users,
      }
    case actionTypes.USERS.PROMOTE:
    default:
      return state
  }
}

export const actions = {
  syncUsersRequest: () => ({
    type: actionTypes.USERS.SYNC_REQUEST,
  }),
  syncUsersSuccess: (users) => ({
    type: actionTypes.USERS.SYNC_SUCCESS,
    users,
  }),
  syncUsersFailure: (error) => ({
    type: actionTypes.USERS.SYNC_FAILURE,
    error,
  }),
  promoteUsers: (userIds, onSuccess, onError) => ({
    type: actionTypes.USERS.PROMOTE,
    userIds,
    onSuccess,
    onError,
  }),
  contactFormSubmit: (from, subject, message, token, onSuccess, onError) => ({
    type: actionTypes.VISITORS.CONTACT_FORM_SUBMIT,
    from,
    subject,
    message,
    token,
    onSuccess,
    onError,
  }),
}

function* syncUsersSaga(/*action*/) {
  const isAdmin = yield select((state) => state.auth.isAdmin)
  //only sync users for admin, else return empty array
  let syncUsersTask
  if (isAdmin) {
    /**
     * @type {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}
     */
    const colRef = db.collection('users')
    // Start the sync task
    syncUsersTask = yield fork(rsf.firestore.syncCollection, colRef, {
      transform: FirestoreHelpers.receiveUsers,
      successActionCreator: actions.syncUsersSuccess,
      failureActionCreator: actions.syncUsersFailure,
    })
  } else {
    yield put(actions.syncUsersSuccess([]))
  }

  // Wait for the logout action, then stop sync
  yield take(authActionTypes.LOGOUT_SUCCESS)

  //cancel sync task if it was started
  if (syncUsersTask) {
    yield cancel(syncUsersTask)
  }
}

function* promoteUsersSaga(action) {
  const userIds = action.userIds

  try {
    const promoteToPublisher = functions.httpsCallable(
      'promoteUsersToPublishers'
    )
    //call function with object containing value
    const result = yield call(promoteToPublisher, { userIds })
    action.onSuccess(result.data)
  } catch (e) {
    action.onError(e)
  }
}

function* contactFormSubmitSage(action) {
  try {
    //instead of calling call(searchAlgoliaForSymbols) we use firebase function so we don't have to expose API key
    const contactUsRequest = functions.httpsCallable('contactUs')
    const payload = {
      from: action.from,
      subject: action.subject,
      message: action.message,
      token: action.token,
    }
    const result = yield call(contactUsRequest, payload)
    if (result.data) {
      action.onSuccess()
    } else {
      action.onError({
        message: 'Recaptcha validation failed. Stop hacking. ;)',
      })
    }
  } catch (e) {
    action.onError(
      e.message
        ? e
        : {
            ...e,
            message: 'Form submission failed, please try again later.',
          }
    )
  }
}

export function* saga() {
  yield all([
    takeLatest(actionTypes.USERS.PROMOTE, promoteUsersSaga),
    takeLatest(
      [actionTypes.USERS.SYNC_REQUEST, authActionTypes.USER_DATA_COMPLETE],
      syncUsersSaga
    ),
    takeLatest(actionTypes.VISITORS.CONTACT_FORM_SUBMIT, contactFormSubmitSage),
  ])
}
