import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import { useSelector } from 'react-redux'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false, exactMatch = false) => {
    return checkIsActive(location, url, exactMatch)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : ''
  }
  const { isAdmin, isPublisher } = useSelector((state) => state.auth)
  const isAdminOrPublisher = isAdmin || isPublisher

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Signals */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Signals</h4>
          <i className="menu-icon flaticon-more-v2" />
        </li>
        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/signals',
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/signals">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Shopping/Chart-bar1.svg')}
              />
            </span>
            <span className="menu-text">Signals</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Signals</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  '/signals/live',
                  true
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/signals/live">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Live Signal</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive('/signals/closed')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/signals/closed">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Closed Signals</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  '/signals/all',
                  true
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/signals/all">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">All Signals</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {isAdminOrPublisher && (
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                '/signals/create',
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/signals/create">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')}
                  />
                </span>
                <span className="menu-text">New Signal</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        )}
        {/*end::1 Level*/}

        {/* Groups */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Groups</h4>
          <i className="menu-icon flaticon-more-v2" />
        </li>
        {/* end:: section */}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/groups', false, true)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/groups">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Communication/Group.svg')}
              />
            </span>
            <span className="menu-text">Groups</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {isAdminOrPublisher ? (
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                '/groups/create',
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/groups/create">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')}
                  />
                </span>
                <span className="menu-text">New Group</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        ) : (
          <>
            {/* Signals */}
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Publishers</h4>
              <i className="menu-icon flaticon-more-v2" />
            </li>
            {/* end:: section */}
            {/*begin::1 Level*/}

            <li
              className={`menu-item ${getMenuItemActive(
                '/publishers/request-access',
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/publishers/request-access">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')}
                  />
                </span>
                <span className="menu-text">Request Access</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        )}
        {/*end::1 Level*/}

        {/* Integrations */}
        {isAdminOrPublisher && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Integrations</h4>
              <i className="menu-icon flaticon-more-v2" />
            </li>
            {/* end:: section */}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                '/integrations',
                false,
                true
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/integrations">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}
                  />
                </span>
                <span className="menu-text">Integrations</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive(
                '/integrations/create',
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link" to="/integrations/create">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Navigation/Plus.svg')}
                  />
                </span>
                <span className="menu-text">New Integration</span>
              </NavLink>
            </li>
          </>
        )}
        {/* Users */}
        {isAdmin && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Users</h4>
              <i className="menu-icon flaticon-more-v2" />
            </li>
            {/* end:: section */}

            {/*begin::1 Level*/}
            <li
              className={`menu-item ${getMenuItemActive('/users', false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/users">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}
                  />
                </span>
                <span className="menu-text">All Users</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        )}
        {/* end:: Users */}
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
