const FieldNames = {
  EMAIL: 'email',
  PASSWORD: 'password',
  USERNAME: 'username',
  CONFIRM_PASSWORD: 'confirmPassword',
  ORIGINAL_PASSWORD: 'currentPassword',
  ACCEPT_TERMS: 'acceptTerms',
  DISPLAY_NAME: 'displayName',
}
export default FieldNames
