import { Close as CloseIcon } from '@material-ui/icons'
import { DialogTitle, IconButton } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

const DialogTitleWithCloseIcon = ({ children, dialogClose, disabled }) => {
  const classes = useStyles()
  return (
    <DialogTitle>
      {children}
      <IconButton
        className={classes.closeIcon}
        disabled={disabled}
        edge="start"
        color="inherit"
        onClick={dialogClose}
        aria-label="close"
        size="small"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}

const useStyles = makeStyles((/*theme*/) => ({
  closeIcon: {
    float: 'right',
  },
}))

DialogTitleWithCloseIcon.propTypes = {
  children: PropTypes.node.isRequired,
  dialogClose: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default DialogTitleWithCloseIcon
