import { selectUserDetails } from './auth'

const selectUserGroups = (state) => {
  const groups = []
  const userDetails = selectUserDetails(state)
  if (userDetails.privateGroupMembership) {
    groups.push(...userDetails.privateGroupMembership)
  }
  if (userDetails.signalGroupsFollowing) {
    groups.push(...userDetails.signalGroupsFollowing)
  }
  return groups
}

export default {
  selectUserGroups,
}
