import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const AndroidIcon = ({ className, ...props }) => {
  return <span {...props} className={clsx('socicon-android', className)} />
}

AndroidIcon.propTypes = {
  className: PropTypes.string,
}
export default AndroidIcon
