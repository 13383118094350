import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

const FormSubmitButton = ({
  children,
  loading,
  disabled,
  color,
  variant,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      type="submit"
      disabled={disabled}
      variant={variant}
      color={color}
      endIcon={loading && <CircularProgress size={12} />}
    >
      {children}
    </Button>
  )
}

FormSubmitButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  variant: PropTypes.string,
}

export default FormSubmitButton
