import * as yup from 'yup'
import basePostValidatorObj from './basePostValidator'
import wpModel from '../generalWordpressModelValidator'

const signalCreatedValidator = {
  enabled: yup.boolean().required(),
  title: yup.string().trim().required(),
  status: yup.string().trim().required(),
  categories: yup.array(yup.object(wpModel).required()),
  tags: yup.array(yup.object(wpModel).required()),
  ...basePostValidatorObj,
}
export default signalCreatedValidator
