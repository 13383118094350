const FieldNames = {
  GROUP: 'group',
  SYMBOL: 'symbol',
  TV_IDEA_URL: 'tvIdeaUrl',
  TV_TIME_INTERVAL: 'tvTimeInterval',
  PHOTO_URL: 'photoUrl',
  URL: 'url',
  NOTES: 'notes',
  DIRECTION: 'direction',
  LEVERAGE: 'leverage',
  TIMEFRAME: 'timeframe',
  //TODO: we pass prop.name into PositionPriceInputs (possible options are "target", "entry", "stop"- we append "_prices" automatically to determine field name)
  //TODO: rename _prices to targets, entries and stops
  TARGET_PRICES: 'target_prices',
  ENTRY_PRICES: 'entry_prices',
  STOP_PRICES: 'stop_prices',
  PL: 'pl',

  //signal update/close
  COMMENT: 'comment',
  //signal close
  REASON: 'reason',
}
export default FieldNames
