const handledExceptions = [
  'auth/email-already-in-use',
  'auth/invalid-email',
  'auth/user-disabled',
  'auth/weak-password',
  'auth/user-not-found',
  'auth/wrong-password',
  // 'auth/operation-not-allowed', //we won't handle this (UNHANDLED_EXCEPTION bellow)
]

/**
 * Calls onError for exceptions that we handle. If exception can't be handled it returns `false`, displays
 * a general message
 * We need special implementation for mobile since we don't/can't use react INTL there
 * @param error { {code: string, message: boolean } }
 * @param onError {function}
 * @param isMobile {boolean} - we use this since intl doesn't work in native: https://github.com/facebook/hermes/issues/23
 * @returns {boolean}
 */
function handleException(error, onError, isMobile = false) {
  if (!handledExceptions.includes(error.code)) {
    isMobile
      ? onError('Unknown error occurred. Please try again later.') //we can't use intl format messages here
      : onError({ id: 'AUTH.VALIDATION.UNHANDLED_EXCEPTION' })

    return false
  }
  if (isMobile) {
    const message = error.message.replace(`[${error.code}] `, '')
    onError(message)
  } else {
    const code = error.code
      .replace(/auth\//g, '')
      .replace(/-/g, '_')
      .toUpperCase()

    onError({ id: 'AUTH.VALIDATION.' + code })
  }

  return true
}

export default { handleException }
