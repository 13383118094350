import {
  shape,
  oneOfType,
  objectOf,
  arrayOf,
  instanceOf,
  string,
  object,
  func,
  number,
  bool,
} from 'prop-types'

const regex = instanceOf(RegExp)

const user = shape({
  uid: string.isRequired,
  email: string.isRequired,
})

const users = arrayOf(user.isRequired)

const publisherRequest = oneOfType([
  shape({
    existingGroup: string.isRequired,
    group: shape({
      name: string.isRequired,
      telegramChannel: string,
      websiteUrl: string,
      membersNumber: string.isRequired,
      note: string,
    }),
  }),
  shape({
    existingGroup: string.isRequired,
    person: shape({
      name: string.isRequired,
      introduction: string.isRequired,
      motivation: string.isRequired,
    }),
  }),
])

const userDetails = shape({
  id: string.isRequired,
  username: string.isRequired,
  signalGroupsFollowing: arrayOf(string.isRequired),
  privateGroupRequests: arrayOf(string.isRequired),
  publisherRequest: publisherRequest,
  createdAt: number.isRequired,
  updatedAt: number.isRequired,
})
const usersDetails = arrayOf(userDetails)
const userDetailsSecrets = shape({
  roles: arrayOf(string).isRequired,
}).isRequired

/**
 * SIGNALS AND GROUPS
 */
const symbolDetails = shape({ code: string, name: string })
const symbolOption = shape({
  symbol: string.isRequired,
  asset: symbolDetails.isRequired,
  currency: symbolDetails.isRequired,
  objectId: string,
  description: string.isRequired,
  type: string.isRequired,
  exchange: string.isRequired,
  country: string,
  typespecs: arrayOf(string.isRequired),
})
const symbolOptions = arrayOf(symbolOption)
const signalBase = {
  id: string, //can be missing (adding signal to state but waiting for DB sync)
  direction: string.isRequired,
  leverage: number.isRequired,
  symbol: symbolOption,
  closed: shape({
    at: number.isRequired,
    comment: string.isRequired,
    reason: string.isRequired,
  }),
  createdAt: number.isRequired,
}
const signalShort = shape(signalBase)
const signalsShort = arrayOf(signalShort)
const signalGroupBase = {
  id: string.isRequired,
  name: string.isRequired,
}
const membershipRequests = objectOf(
  shape({
    email: string.isRequired,
    createdAt: number.isRequired,
  })
)
const membersDetails = objectOf(
  shape({
    email: string.isRequired,
    createdAt: number.isRequired,
  })
)
const signalGroupMobileRoute = shape({
  params: shape({ signalGroupId: string }).isRequired,
})
const members = arrayOf(string)
const signalGroupShort = shape(signalGroupBase)
const signalGroup = shape({
  ...signalGroupBase,
  owner: string.isRequired,
  url: string,
  visibility: string.isRequired,
  isVerified: bool,
  members: members,
  membershipRequests: membershipRequests,
  signalsLive: arrayOf(signalShort),
  createdAt: number.isRequired,
  updatedAt: number.isRequired,
})

const signalGroups = arrayOf(signalGroup.isRequired)

const position = shape({
  price: string.isRequired,
  size: number,
  hit: bool,
})

const positions = arrayOf(position)

const signalMobileRoute = shape({
  params: shape({ signalId: string }).isRequired,
})
const signal = shape({
  ...signalBase,
  entry_prices: arrayOf(position.isRequired).isRequired,
  stop_prices: arrayOf(position.isRequired).isRequired,
  target_prices: arrayOf(position.isRequired).isRequired,
  group: signalGroupShort,
  notes: string,
  timeframe: string,
  tvIdeaUrl: string,
  tvTimeInterval: string,
  photoUrl: string,
  url: string,
  owner: string.isRequired,
  updatedAt: number.isRequired,
  createdAt: number.isRequired,
})

const signals = arrayOf(signal.isRequired)

/**
 * 3RD PARTY
 */
const intl = shape({
  formatMessage: func.isRequired,
})

const navigation = shape({
  push: func.isRequired,
  navigate: func.isRequired,
  setParams: func.isRequired,
  goBack: func.isRequired,
})

const colors = shape({
  green: string.isRequired,
  red: string.isRequired,
  text: string.isRequired,
  background: string.isRequired,
  onBackground: string.isRequired,
  surface: string.isRequired,
  onSurface: string.isRequired,
  primary: string.isRequired,
  accent: string.isRequired,
  backdrop: string.isRequired,
  error: string.isRequired,
  disabled: string.isRequired,
  placeholder: string.isRequired,
  notification: string.isRequired,
})

const theme = shape({
  colors,
})

const history = shape({
  push: func.isRequired,
})

const route = shape({
  params: object.isRequired,
})

const match = shape({
  params: shape({ id: string.isRequired }).isRequired,
})

const collectionRef = shape({
  startAfter: func.isRequired,
  limit: func.isRequired,
  get: func.isRequired,
})

const customFlatListBase = {
  endReached: bool.isRequired,
  newDataReady: bool.isRequired,
  firstDoc: string,
  lastDoc: string,
}

const signalGroupsCustomFlatList = shape({
  data: signalGroups,
  ...customFlatListBase,
})

const signalsCustomFlatList = shape({
  data: signals,
  ...customFlatListBase,
})

const wordpressMetadataModel = shape({
  id: number.isRequired,
  name: string.isRequired,
  link: string.isRequired,
})
const wordpressClient = shape({
  endpoint: string.isRequired,
  username: string.isRequired,
  password: string.isRequired,
})
const telegramClient = shape({
  botToken: string.isRequired,
  chatId: string.isRequired,
})
const integrationClient = oneOfType([wordpressClient, telegramClient])
const baseIntegrationObj = () => ({
  id: string.isRequired,
  syncedAt: number,
  createdAt: number.isRequired,
  updatedAt: number.isRequired,
})

const integrationResource = shape({
  type: string.isRequired,
  typeName: string.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  url: string.isRequired,
})
const wordpressCategories = arrayOf(wordpressMetadataModel)
const wordpressTags = arrayOf(wordpressMetadataModel)
const wordpressData = shape({
  categories: wordpressCategories.isRequired,
  tags: wordpressTags.isRequired,
})
const wordpressIntegration = shape({
  ...baseIntegrationObj(),
  resource: integrationResource.isRequired,
  data: wordpressData.isRequired,
})
const telegramIntegrationData = shape({
  type: string.isRequired,
  id: number.isRequired,
  title: string.isRequired,
  permissions: object.isRequired,
})
const telegramIntegration = shape({
  ...baseIntegrationObj(),
  resource: integrationResource.isRequired,
  data: telegramIntegrationData.isRequired,
})

const integration = oneOfType([wordpressIntegration, telegramIntegration])
const integrations = arrayOf(integration)

const wordpressCreatePostData = shape({
  status: string.isRequired,
  title: string.isRequired,
  body: string.isRequired,
  categories: arrayOf(wordpressMetadataModel),
  tags: arrayOf(wordpressMetadataModel),
})

const telegramCreatePostData = shape({
  enabled: bool.isRequired,
  sendType: string.isRequired,
  sendMessage: shape({
    message: string.isRequired,
    disableWebPagePreview: bool.isRequired,
  }),
  sendPhoto: shape({
    photoUrl: string.isRequired,
    caption: string.isRequired,
  }),
})

const connectorData = {
  signalCreated: oneOfType([wordpressCreatePostData, telegramCreatePostData]),
  signalUpdated: oneOfType([telegramCreatePostData]),
  signalClosed: oneOfType([telegramCreatePostData]),
}

const connectorDataShape = shape(connectorData)
const placeholderReplacements = arrayOf(
  shape({
    key: string.isRequired,
    value: string.isRequired,
    search: regex.isRequired,
    replacement: oneOfType([string.isRequired, number.isRequired]),
    text: string.isRequired,
  })
)

const connector = shape({
  id: string.isRequired,
  groupId: string.isRequired,
  integration: integration.isRequired,
  ...connectorData,
})

export default {
  user,
  users,
  userDetails,
  usersDetails,
  symbolOption,
  symbolOptions,
  userDetailsSecrets,
  members,
  membersDetails,
  membershipRequests,
  signalGroupMobileRoute,
  signalGroupShort,
  signalGroup,
  signalGroups,
  signalGroupsCustomFlatList,
  signalShort,
  signalsShort,
  signalMobileRoute,
  signal,
  signals,
  signalsCustomFlatList,
  position,
  positions,
  navigation,
  colors,
  theme,
  intl,
  history,
  route,
  match,
  collectionRef,
  publisherRequest,
  wordpressTags,
  wordpressCategories,
  telegramIntegrationData,
  integration,
  integrations,
  integrationClient,
  connector,
  connectorData: connectorDataShape,
  placeholderReplacements,
  wordpressCreatePostData,
  telegramCreatePostData,
}
