import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import { /*toAbsoluteUrl,*/ checkIsActive } from '../../../../_helpers'
import { useSelector } from 'react-redux'

export function HeaderMenu({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? 'menu-item-active' : ''
  }
  const { isAdmin } = useSelector((state) => state.auth)
  // const isAdminOrPublisher = isAdmin || isPublisher

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive(
            '/dashboard'
          )}`}
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="menu-text">Dashboard</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive('/signals')}`}
        >
          <NavLink className="menu-link" to="/signals/all">
            <span className="menu-text">Signals</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-rel ${getMenuItemActive('/groups')}`}
        >
          <NavLink className="menu-link" to="/groups">
            <span className="menu-text">Groups</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*{!isAdminOrPublisher && (*/}
        {/*  <>*/}
        {/*    /!*begin::1 Level*!/*/}
        {/*    <li*/}
        {/*      className={`menu-item menu-item-rel ${getMenuItemActive(*/}
        {/*        '/publishers/request-access'*/}
        {/*      )}`}*/}
        {/*    >*/}
        {/*      <NavLink className="menu-link" to="/publishers/request-access">*/}
        {/*        <span className="menu-text">Publishers</span>*/}
        {/*        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}*/}
        {/*      </NavLink>*/}
        {/*    </li>*/}
        {/*    /!*end::1 Level*!/*/}
        {/*  </>*/}
        {/*)}*/}
        {isAdmin && (
          <>
            {/*begin::1 Level*/}
            <li
              className={`menu-item menu-item-rel ${getMenuItemActive(
                '/users'
              )}`}
            >
              <NavLink className="menu-link" to="/users">
                <span className="menu-text">Users</span>
                {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
              </NavLink>
            </li>
            {/*end::1 Level*/}
          </>
        )}
      </ul>
      {/*end::Header Nav*/}
    </div>
  )
}
