import * as yup from 'yup'

const passwordValidator = yup.string().trim().max(255)

const client = (mandatoryFields = { endpoint: false, password: false }) =>
  yup.object({
    endpoint: yup
      .string()
      .url()
      .trim()
      .required()
      .matches(/^(https):\/\//, 'WP Admin URL should start with "https://"')
      .matches(/\/wp-json\/?$/, 'WP Admin URL should end with with "/wp-json"'),
    username: yup.string().trim().required().max(255),
    //password is optional by default, but can be required
    password: yup.lazy(() =>
      mandatoryFields.password
        ? passwordValidator.required()
        : passwordValidator
    ),
  })

export default { client }
