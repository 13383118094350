// USER
const selectUser = (state) => state.auth.user
const selectUserId = (state) => selectUser(state).uid
const redirectRoute = (state) => state.auth.redirectToUrl
// USER DETAILS
export const selectUserDetails = (state) => state.auth.userDetails
const selectUserDevices = (state) => selectUserDetails(state).devices
const selectSignalGroupsFollowing = (state) =>
  selectUserDetails(state).signalGroupsFollowing
const selectPrivateGroupMembership = (state) =>
  selectUserDetails(state).privateGroupMembership
const selectPrivateGroupRequests = (state) =>
  selectUserDetails(state).privateGroupRequests

function countUserGroups(state) {
  const userDetails = selectUserDetails(state)
  //we need to use privateGroupRequests, since user will most likely be accepted and this way he doesn't end up with
  // 11 out of 10 groups subscribed
  const privateGroupsCount = userDetails.privateGroupRequests
    ? userDetails.privateGroupRequests.length
    : 0
  const publicGroupsCount = userDetails.signalGroupsFollowing
    ? userDetails.signalGroupsFollowing.length
    : 0
  return privateGroupsCount + publicGroupsCount
}
export default {
  // USER
  selectUser,
  selectUserId,
  redirectRoute,
  // USER DETAILS
  selectUserDetails,
  selectUserDevices,
  selectSignalGroupsFollowing,
  selectPrivateGroupMembership,
  selectPrivateGroupRequests,
  countUserGroups,
}
