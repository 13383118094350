import React from 'react'
import ThirdPartyPrivacyLinks from './ThirdPartyPrivacyLinks'

const TermsAndConditions = () => {
  return (
    <div>
      <p>
        By downloading app or using the website, these terms will automatically
        apply to you – you should make sure therefore that you read them
        carefully before using the platform. You&apos;re not allowed to copy, or
        modify the website or app, any part of the platform, or our trademarks
        in any way. You&apos;re not allowed to attempt to extract the source
        code of the platform, and you also shouldn&apos;t try to translate the
        platform into other languages, or make derivative versions. The platform
        itself, and all the trade marks, copyright, database rights and other
        intellectual property rights related to it, still belong to
        TradingSignally.com.
      </p>
      <p>
        TradingSignally.com is committed to ensuring that the platform is as
        useful and efficient as possible. For that reason, we reserve the right
        to make changes to the platform or to charge for its services, at any
        time and for any reason. We will never charge you for the platform or
        its services without making it very clear to you exactly what
        you&apos;re paying for.
      </p>
      <p>
        The TradingSignally platform stores and processes personal data that you
        have provided to us, in order to provide our Service. It&apos;s your
        responsibility to keep your phone and access to the platform secure. We
        therefore recommend that you do not jailbreak or root your phone, which
        is the process of removing software restrictions and limitations imposed
        by the official operating system of your device. It could make your
        phone vulnerable to malware/viruses/malicious programs, compromise your
        phone&apos;s security features and it could mean that the
        TradingSignally platform won&apos;t work properly or at all.
      </p>
      <div>
        <p>
          The platform does use third party services that declare their own
          Terms and Conditions.
        </p>
        <p>
          Link to Terms and Conditions of third party service providers used by
          the platform
        </p>
        <ThirdPartyPrivacyLinks />
      </div>
      <p>
        You should be aware that there are certain things that
        TradingSignally.com will not take responsibility for. Certain functions
        of the platform will require the platform to have an active internet
        connection. The connection can be Wi-Fi, or provided by your mobile
        network provider, but TradingSignally.com cannot take responsibility for
        the platform not working at full functionality if you don&apos;t have
        access to Wi-Fi, and you don&apos;t have any of your data allowance
        left.
      </p>
      <p>
        If you&apos;re using the platform outside of an area with Wi-Fi, you
        should remember that your terms of the agreement with your mobile
        network provider will still apply. As a result, you may be charged by
        your mobile provider for the cost of data for the duration of the
        connection while accessing the platform, or other third party charges.
        In using the platform, you&apos;re accepting responsibility for any such
        charges, including roaming data charges if you use the platform outside
        of your home territory (i.e. region or country) without turning off data
        roaming. If you are not the bill payer for the device on which
        you&apos;re using the platform, please be aware that we assume that you
        have received permission from the bill payer for using the platform.
      </p>
      <p>
        Along the same lines, TradingSignally.com cannot always take
        responsibility for the way you use the platform i.e. You need to make
        sure that your device stays charged – if it runs out of battery and you
        can&apos;t turn it on to avail the Service, TradingSignally.com cannot
        accept responsibility.
      </p>
      <p>
        With respect to TradingSignally.com&apos;s responsibility for your use
        of the platform, when you&apos;re using the platform, it&apos;s
        important to bear in mind that although we endeavour to ensure that it
        is updated and correct at all times, we do rely on third parties to
        provide information to us so that we can make it available to you.
        TradingSignally.com accepts no liability for any loss, direct or
        indirect, you experience as a result of relying wholly on this
        functionality of the platform.
      </p>
      <p>
        At some point, we may wish to update the platform. The platform is
        currently available on Android &amp; iOS – the requirements for both
        systems(and for any additional systems we decide to extend the
        availability of the platform to) may change, and you&apos;ll need to
        download the updates if you want to keep using the platform.
        TradingSignally.com does not promise that it will always update the
        platform so that it is relevant to you and/or works with the Android
        &amp; iOS version that you have installed on your device. However, you
        promise to always accept updates to the application when offered to you,
        We may also wish to stop providing the platform, and may terminate use
        of it at any time without giving notice of termination to you. Unless we
        tell you otherwise, upon any termination, (a) the rights and licenses
        granted to you in these terms will end; (b) you must stop using the
        platform, and (if needed) delete it from your device.
      </p>
      <p>
        <strong>Disclaimer of Liability</strong>
      </p>
      <p>
        Investments in securities, commodities, currencies, cryptocurrencies,
        derivatives and other investment options are speculative and involve
        high degrees of risk. You could lose all or a substantial amount of your
        investment. Each investment is unique and entails unique risks. You
        should carefully read any and all offering materials and related
        information for specific risks and other important information regarding
        any investment, and consult with your advisors, before investing. This
        platform does not list, and does not purport to list, the risk factors
        associated with any investment. The past trading performance of any
        trading program or instrument discussed herein is not indicative or a
        guarantee of future results.
      </p>
      <p>
        We are not a registered broker-dealer or an investment advisor. The
        services and information we offer are for sophisticated investors, and
        do not constitute personal investment advice, which of necessity must be
        tailored to your particular means and needs. Nothing in our Services,
        Website, Mobile Apps, emails or other messages shall be deemed a
        solicitation or an offer to buy/sell. No representation is being made
        that any stock, security, commodity, currency, cryptocurrency or any
        derivative will or is likely to achieve trading profits or trading
        losses, as predictions by their very nature are distinct from
        guarantees.
      </p>
      <p>
        The past or recent trading performance of any stock, security,
        commodity, currency, cryptocurrency or any derivative is not necessarily
        indicative of future profits/losses. We do not and can not guarantee
        that use of our Services will generate you profits. We do not and can
        not take responsibility for any losses to your accounts. You must trade
        and take sole responsibility to evaluate all information provided by
        this platform and use it at your own risk. All trading information we
        provide is intended as trade assistance only.
      </p>
      <p>
        The platform owner shall not be responsible for and disclaims all
        liability for any loss, liability, damage (whether direct, indirect or
        consequential), personal injury or expense of any nature whatsoever
        which may be suffered by you or any third party (including your
        company), as a result of or which may be attributable, directly or
        indirectly, to your access and use of the platform, any information
        contained on the platform, your or your company&apos;s personal
        information or material and information transmitted over our system. In
        particular, neither the platform owner nor any third party or data or
        content provider shall be liable in any way to you or to any other
        person, firm or corporation whatsoever for any loss, liability, damage
        (whether direct or consequential), personal injury or expense of any
        nature whatsoever arising from any delays, inaccuracies, errors in, or
        omission of any share price information or the transmission thereof, or
        for any actions taken in reliance thereon or occasioned thereby or by
        reason of non-performance or interruption, or termination thereof.
      </p>
      <p>
        We make no guarantees as to the accuracy, thoroughness or quality of the
        information on this platform or any message posted on it, which is
        provided only on an “AS-IS” and “AS AVAILABLE” basis at User&apos;s sole
        risk. TradingSignally shall not be responsible or liable for any errors,
        omissions or inaccuracies in the platform or message content. The
        information provided by the Services and the platform are neither
        comprehensive nor appropriate for every individual. Some of the
        information may not be relevant to or compliant with the laws,
        regulations or other legal requirements of your country of residence. It
        is your responsibility to determine whether, how and to what extent your
        intended use of the Services and platform will be technically and
        legally possible in the areas of the world where you intend to use them.
        You are advised to verify any information before using it for any
        personal, financial or business purpose. The platform content and the
        Services may be modified at any time by us, without advance notice or
        reason, and we shall have no obligation to notify you of any corrections
        or changes to any platform content.
      </p>
      <p>
        The information contained in this platform is not intended to provide
        legal, accounting, tax or specific investment advice. If such advice is
        required, the services of a competent professional advisor should be
        sought.
      </p>
      <p>
        TradingSignally will not be liable to you or anyone else for any loss or
        injury resulting directly or indirectly from the use of this platform or
        the services we offer, caused in whole or part by its negligence or
        compiling, interpreting, reporting or delivering this site and any
        content through this site. In no event will the TradingSignally or any
        of its officers, agents or licensors be liable to you or anyone else for
        any decision made or action taken by you in reliance on such content.
      </p>
      <p>
        <strong>Warranties</strong>
      </p>
      <p>
        TradingSignally platform owner makes no warranties, representations,
        statements or guarantees (whether express, implied in law or residual)
        regarding the website.
      </p>
      <p>
        IN TRADING, THERE CAN BE NO ASSURANCE THAT ANY PRIOR SUCCESSES OR PAST
        RESULTS AS TO INCOME EARNINGS CAN BE USED AS AN INDICATION OF FUTURE
        SUCCESS OR RESULTS. OUR SERVICES ARE FOR INFORMATIONAL PURPOSES ONLY.
        USE CAUTION AND SEEK THE ADVICE OF QUALIFIED PROFESSIONALS. CHECK WITH
        YOUR ACCOUNTANT, LAWYER OR FINANCIAL ADVISOR BEFORE ACTING ON ANY
        INFORMATION PROVIDED BY OUR SERVICES.
      </p>
      <p>
        USERS OF OUR SERVICES AND WEBSITE ARE ADVISED TO PERFORM THEIR OWN DUE
        DILIGENCE WHEN IT COMES TO MAKING INVESTMENT DECISIONS.
      </p>
      <p>
        <strong>Changes to This Terms and Conditions</strong>
      </p>
      <p>
        We may update our Terms and Conditions from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Terms and Conditions on this page.
      </p>
      <p>These terms and conditions are effective as of 2020-06-01</p>
      <p>
        <strong>Contact Us</strong>
      </p>
      <p>
        If you have any questions or suggestions about our Terms and Conditions,
        do not hesitate to contact us at{' '}
        <a href="mailto:info@tradingsignally.com">info@tradingsignally.com</a>.
      </p>
    </div>
  )
}

export default TermsAndConditions
