import * as yup from 'yup'

const client = () =>
  yup.object({
    botToken: yup.string().trim().required().max(255),
    chatId: yup
      .string()
      .trim()
      .required()
      .max(255)
      .matches(
        /^-\d+$/,
        'chat id should start with - character followed by numbers'
      ),
  })

export default { client }
