import * as yup from 'yup'
import signalGroupsHelpers from '../../helpers'

const LOGO_VALIDATION = signalGroupsHelpers.LOGO_VALIDATION
const BASE64_VALIDATION_REGEX = RegExp(
  `^data:image/(${LOGO_VALIDATION.SUPPORTED_EXTENSIONS.join('|')});base64,`
)

const schemaSpec = yup.object({
  name: yup.string().trim().min(3).max(255).required(),
  visibility: yup
    .string()
    .trim()
    .matches(/^(private|public)$/)
    .required(),
  websiteUrl: yup.string().url().trim().max(255),
  description: yup.string().trim().max(80000),
  logo: yup
    .object({
      base64: yup
        .string()
        .required()
        .test(
          'base64Validity',
          'File type is not supported.',
          (value) => !!value.match(BASE64_VALIDATION_REGEX)
        ),
      size: yup
        .number()
        .required()
        .max(LOGO_VALIDATION.MAX_FILE_SIZE_IN_MB * 1024 * 1024),
      // name: yup
      //   .string()
      //   .required()
      //   .test(
      //     'fileExtension',
      //     'File extension is not supported',
      //     fileHasValidExtension
      //   ),
      type: yup.string().required(),
      // .test('fileType', 'File type is not supported.', (value) =>
      //   LOGO_VALIDATION.SUPPORTED_FORMATS.includes(value)
      // ),
      // lastModified: yup.number().required(),
      dimensions: yup
        .object({
          width: yup.number().min(LOGO_VALIDATION.MIN_IMAGE_WIDTH).required(),
          height: yup.number().min(LOGO_VALIDATION.MIN_IMAGE_HEIGHT).required(),
        })
        .required(),
    })
    .nullable(),
  // .required('Logo is required')
})

export default schemaSpec
