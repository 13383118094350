import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@material-ui/core'
import * as auth from '../../../redux/ducks/auth.duck'
import PropTypes from 'prop-types'
import { registrationValidationSchema, CustomPropTypes } from '@tad3j/shared'
import GeneralDialog from '../../partials/dialogs/GeneralDialog'
import TermsAndConditions from '../../partials/policies/TermsAndConditions'
import { Helmet } from 'react-helmet'

const style = { display: 'block' }

function Registration({ intl, register }) {
  const [loading, setLoading] = useState(false)
  const handleFormSubmit = (values, { setStatus }) => {
    setLoading(true)
    const onSuccess = () => {
      setLoading(false)
    }
    const onError = (message) => {
      setLoading(false)
      setStatus(intl.formatMessage(message))
    }

    register(values, onSuccess, onError)
  }

  return (
    <>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <div className="login-form login-signin" style={style}>
        <div className="text-center mb-5 mb-lg-10">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.REGISTER.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">
            Enter your details to create an account
          </p>
        </div>

        <Formik
          initialValues={{
            email: '',
            // username: '',
            password: '',
            acceptTerms: false,
            confirmPassword: '',
          }}
          onSubmit={handleFormSubmit}
          validationSchema={registrationValidationSchema}
        >
          {({ values, status, errors, touched, handleChange, handleBlur }) => (
            <Form
              noValidate
              autoComplete="off"
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              {/* begin: Alert */}
              {status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{status}</div>
                </div>
              )}
              {/* end: Alert */}

              {/* begin: Email */}
              <div className="form-group fv-plugins-icon-container">
                <TextField
                  variant="filled"
                  label="Email"
                  className="form-control form-control-solid h-auto"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>
              {/* end: Email */}

              {/* begin: Password */}
              <div className="form-group fv-plugins-icon-container">
                <TextField
                  variant="filled"
                  type="password"
                  label="Password"
                  className="form-control form-control-solid h-auto"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>
              {/* end: Password */}

              {/* begin: Confirm Password */}
              <div className="form-group fv-plugins-icon-container">
                <TextField
                  variant="filled"
                  type="password"
                  label="Confirm Password"
                  className="form-control form-control-solid h-auto"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
              </div>
              {/* end: Confirm Password */}

              {/* begin: Terms and Conditions */}
              <div className="form-group">
                <FormControlLabel
                  label={
                    <>
                      I agree to{' '}
                      <GeneralDialog
                        ariaName="terms-and-conditions"
                        title="Terms & Conditions"
                        linkText="terms & conditions"
                        keepMounted
                      >
                        <TermsAndConditions />
                      </GeneralDialog>
                    </>
                  }
                  control={
                    <Checkbox
                      color="primary"
                      name="acceptTerms"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.acceptTerms}
                    />
                  }
                />
                {touched.acceptTerms && errors.acceptTerms && (
                  <FormHelperText error={true}>
                    {touched.acceptTerms && errors.acceptTerms}
                  </FormHelperText>
                )}
              </div>
              {/* end: Terms and Conditions */}

              <div className="form-group d-flex flex-wrap flex-center">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <span>Submit</span>
                  {loading && <span className="ml-3 spinner spinner-white" />}
                </button>
                <Link to="/auth">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Back to Sign In
                  </button>
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

Registration.propTypes = {
  intl: CustomPropTypes.intl.isRequired,
  register: PropTypes.func.isRequired,
}
const mapDispatchToProps = {
  register: auth.actions.register,
}
export default injectIntl(connect(null, mapDispatchToProps)(Registration))
