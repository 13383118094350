import { flow, set } from 'lodash/fp'

const setRegisterSuccess = (id, email, username, now) => (state) => {
  const data = {
    id,
    isReader: true,
    email,
    username,
    createdAt: now,
    updatedAt: now,
  }
  return flow(
    set('loading', true),
    set('userDetails', data),
    set('userSecrets', data)
  )(state)
}

export default { setRegisterSuccess }
