import React, { Component } from 'react'
import * as authActions from '../../../redux/ducks/auth.duck'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { LayoutSplashScreen } from '../../../_metronic/layout'
import PropTypes from 'prop-types'

class Logout extends Component {
  componentDidMount() {
    this.props.logout()
  }

  render() {
    const { hasAuthToken } = this.props

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />
  }
}

Logout.propTypes = {
  hasAuthToken: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
}
const mapStateToProps = ({ auth }) => ({
  hasAuthToken: Boolean(auth.authToken),
})
const mapDispatchToProps = {
  logout: authActions.actions.logout,
}
export default connect(mapStateToProps, mapDispatchToProps)(Logout)
