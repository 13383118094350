import TradingView from '../../trading-view/index'
import Sanitization from '../../sanitization'

const CLASS_ALL = null
const CLASS_TITLE = 'title'
const CLASS_CREATED = 'created'
const CLASS_UPDATED = 'updated'
const CLASS_CLOSED = 'closed'
const CLASS_PREVIEW = 'preview'
const CLASS_PHOTO_URL = 'photoUrl'

const MAX_POSITIONS = 3

const PLACEHOLDERS = {
  TS_ID: 'TS_ID',
  TS_URL: 'TS_URL',
  TS_LEVERAGE: 'TS_LEVERAGE',
  TS_DIRECTION: 'TS_DIRECTION',
  TS_TIMEFRAME: 'TS_TIMEFRAME',
  TS_SYMBOL_NAME: 'TS_SYMBOL_NAME',
  TS_SYMBOL_CODE: 'TS_SYMBOL_CODE',
  TS_CURRENCY_CODE: 'TS_CURRENCY_CODE',
  TS_SYMBOL_EXCHANGE: 'TS_SYMBOL_EXCHANGE',
  TS_PL: 'TS_PL',
  TS_CUSTOM_URL: 'TS_CUSTOM_URL',
  TS_CUSTOM_PHOTO_URL: 'TS_CUSTOM_PHOTO_URL',
  TS_UPDATED_MESSAGE: 'TS_UPDATED_MESSAGE',
  TS_CLOSED_REASON: 'TS_CLOSED_REASON',
  TS_CLOSED_MESSAGE: 'TS_CLOSED_MESSAGE',
  TS_NOTES: 'TS_NOTES',
  TS_ENTRY_PRICE_1: 'TS_ENTRY_PRICE_1',
  TS_ENTRY_SIZE_1: 'TS_ENTRY_SIZE_1',
  TS_ENTRY_PRICE_2: 'TS_ENTRY_PRICE_2',
  TS_ENTRY_SIZE_2: 'TS_ENTRY_SIZE_2',
  TS_ENTRY_PRICE_3: 'TS_ENTRY_PRICE_3',
  TS_ENTRY_SIZE_3: 'TS_ENTRY_SIZE_3',
  TS_TARGET_PRICE_1: 'TS_TARGET_PRICE_1',
  TS_TARGET_SIZE_1: 'TS_TARGET_SIZE_1',
  TS_TARGET_PRICE_2: 'TS_TARGET_PRICE_2',
  TS_TARGET_SIZE_2: 'TS_TARGET_SIZE_2',
  TS_TARGET_PRICE_3: 'TS_TARGET_PRICE_3',
  TS_TARGET_SIZE_3: 'TS_TARGET_SIZE_3',
  TS_STOP_PRICE_1: 'TS_STOP_PRICE_1',
  TS_STOP_SIZE_1: 'TS_STOP_SIZE_1',
  TS_STOP_PRICE_2: 'TS_STOP_PRICE_2',
  TS_STOP_SIZE_2: 'TS_STOP_SIZE_2',
  TS_STOP_PRICE_3: 'TS_STOP_PRICE_3',
  TS_STOP_SIZE_3: 'TS_STOP_SIZE_3',
  TS_GROUP_ID: 'TS_GROUP_ID',
  TS_GROUP_NAME: 'TS_GROUP_NAME',
  TS_GROUP_VISIBILITY: 'TS_GROUP_VISIBILITY',
  TS_GROUP_URL: 'TS_GROUP_URL',
  TW_SYMBOL_ID: 'TW_SYMBOL_ID',
  TW_IDEA_ID: 'TW_IDEA_ID',
  TW_IDEA_URL: 'TW_IDEA_URL',
}

const replaceWithReplacements = (content, replacements) => {
  Object.keys(replacements).forEach((replacementName) => {
    content = content.replace(
      replacements[replacementName].search,
      replacements[replacementName].replacement
    )
  })
  return content
}

const filterPlaceholders = (placeholders, includedClass) => {
  if (includedClass) {
    return placeholders.filter(({ classes }) => classes.includes(includedClass))
  }
  return placeholders
}

const getPlaceholders = (includedClass, signal, websiteUrl, isPreview) => {
  //use dummy id for preview (if signal doesn't have id)
  const signalId = isPreview
    ? `#${PLACEHOLDERS.TS_ID}#`
    : signal
    ? signal.id
    : null
  const symbol = signal ? signal.symbol : null
  const currencyCode = symbol
    ? symbol.symbol && symbol.currency
      ? symbol.currency.code
      : ''
    : ''
  const lastUpdateMessage = isPreview
    ? signal.comment
    : signal
    ? signal.updates
      ? signal.updates[signal.updates.length - 1].comment
      : ''
    : ''
  const closedReason = signal ? (signal.closed ? signal.closed.reason : '') : ''
  const closedMessage = signal
    ? signal.closed
      ? signal.closed.comment
      : ''
    : ''
  let placeholders = [
    {
      key: PLACEHOLDERS.TS_ID,
      text: 'Signal Id',
      replacement: signalId,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_PREVIEW],
    },
    {
      key: PLACEHOLDERS.TS_URL,
      text: 'Signal URL',
      replacement: isPreview
        ? `#${PLACEHOLDERS.TS_URL}#`
        : signal
        ? `${websiteUrl}/signals/${signalId}`
        : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_PREVIEW],
    },
    // {
    //   key: 'TS_STATUS',
    //   text: 'Status',
    //   replacement: isPreview ? '#TS_STATUS#' : signal ? signal.status : null,
    //   classes: [
    //     CLASS_CREATED,
    //     CLASS_UPDATED,
    //     CLASS_CLOSED,
    //     CLASS_TITLE,
    //     CLASS_PREVIEW,
    //   ],
    // },
    {
      key: PLACEHOLDERS.TS_LEVERAGE,
      text: 'Leverage',
      replacement: signal ? signal.leverage : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
    },
    {
      key: PLACEHOLDERS.TS_DIRECTION,
      text: 'Direction',
      replacement: signal ? signal.direction : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
    },
    {
      key: PLACEHOLDERS.TS_TIMEFRAME,
      text: 'Timeframe',
      replacement: signal ? (signal.timeframe ? signal.timeframe : '') : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
    },
    {
      key: PLACEHOLDERS.TS_SYMBOL_NAME,
      text: 'Symbol Name',
      replacement: symbol
        ? symbol.description
          ? symbol.description
          : ''
        : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
    },
    {
      key: PLACEHOLDERS.TS_SYMBOL_CODE,
      text: 'Symbol Code',
      replacement: symbol ? (symbol.symbol ? symbol.symbol : '') : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
    },
    {
      key: PLACEHOLDERS.TS_CURRENCY_CODE,
      text: 'Currency Code',
      replacement: currencyCode,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
    },
    {
      key: PLACEHOLDERS.TS_SYMBOL_EXCHANGE,
      text: 'Exchange',
      replacement: symbol ? (symbol.exchange ? symbol.exchange : '') : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
    },
    {
      key: PLACEHOLDERS.TS_PL,
      text: 'Profit/Loss',
      replacement: signal ? (signal.pl ? `${signal.pl}%` : '') : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
    },
    {
      key: PLACEHOLDERS.TS_UPDATED_MESSAGE,
      text: 'Signal updated message',
      replacement: lastUpdateMessage,
      classes: [CLASS_UPDATED],
    },
    {
      key: PLACEHOLDERS.TS_CLOSED_REASON,
      text: 'Signal closed reason',
      replacement: closedReason,
      classes: [CLASS_CLOSED],
    },
    {
      key: PLACEHOLDERS.TS_CLOSED_MESSAGE,
      text: 'Signal closed message',
      replacement: closedMessage,
      classes: [CLASS_CLOSED],
    },
    // {
    //   key: 'TS_CREATED_AT',
    //   text: 'Created At',
    //   //we have to check if we have timestamp object and convert it, else pass whatever is there
    //   replacement:
    //     signal && signal.createdAt
    //       ? signal.createdAt.toMillis
    //         ? signal.createdAt.toMillis()
    //         : signal.createdAt
    //       : null,
    //   classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
    // },
    // {
    //   key: 'TS_UPDATED_AT',
    //   text: 'Updated At',
    //   replacement:
    //     signal && signal.updatedAt
    //       ? signal.updatedAt.toMillis
    //         ? signal.updatedAt.toMillis()
    //         : signal.updatedAt
    //       : null,
    //   classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
    // },
    {
      key: PLACEHOLDERS.TS_NOTES,
      text: 'Notes',
      replacement: signal ? (signal.notes ? signal.notes : '') : null,
      classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
    },
  ]
  //positions
  for (let i = 0; i < MAX_POSITIONS; i++) {
    addPositionsReplacements(
      placeholders,
      signal ? signal.entry_prices[i] : null,
      i,
      'Entry',
      currencyCode
    )
  }
  for (let i = 0; i < MAX_POSITIONS; i++) {
    addPositionsReplacements(
      placeholders,
      signal ? signal.target_prices[i] : null,
      i,
      'Target',
      currencyCode
    )
  }
  for (let i = 0; i < MAX_POSITIONS; i++) {
    addPositionsReplacements(
      placeholders,
      signal ? signal.stop_prices[i] : null,
      i,
      'Stop',
      currencyCode
    )
  }
  placeholders.push(
    ...[
      //group
      {
        key: PLACEHOLDERS.TS_GROUP_ID,
        text: 'Group Id',
        replacement: signal ? signal.group.id : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
      },
      {
        key: PLACEHOLDERS.TS_GROUP_NAME,
        text: 'Group Name',
        replacement: signal ? signal.group.name : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
      },
      {
        key: PLACEHOLDERS.TS_GROUP_VISIBILITY,
        text: 'Group Visibility',
        replacement: signal ? signal.group.visibility : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
      },
      {
        key: PLACEHOLDERS.TS_GROUP_URL,
        text: 'Group URL',
        replacement: signal ? `${websiteUrl}/groups/${signal.group.id}` : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
      },
      //trading view
      {
        key: PLACEHOLDERS.TW_SYMBOL_ID,
        text: 'TradingView Symbol Id',
        replacement: signal ? TradingView.getSymbolId(symbol) : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_TITLE],
      },
      {
        key: PLACEHOLDERS.TW_IDEA_ID,
        text: 'TradingView Idea Id',
        replacement: signal ? (signal.tvIdeaUrl ? signal.tvIdeaUrl : '') : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
      },
      {
        key: PLACEHOLDERS.TW_IDEA_URL,
        text: 'TradingView Idea URL',
        replacement: signal
          ? signal.tvIdeaUrl
            ? TradingView.getIdeaIdFromURL(signal.tvIdeaUrl)
            : ''
          : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
      },
      //custom
      {
        key: PLACEHOLDERS.TS_CUSTOM_URL,
        text: 'Custom Signal URL',
        replacement: signal ? signal.url : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
      },
      {
        key: PLACEHOLDERS.TS_CUSTOM_PHOTO_URL,
        text: 'Custom Signal Photo URL',
        replacement: signal ? signal.photoUrl : null,
        classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED, CLASS_PHOTO_URL],
      },
    ]
  )
  return filterPlaceholders(placeholders, includedClass).map(
    ({ key, text, replacement }) => ({
      key,
      value: `#${key}#`,
      search: new RegExp(`#${key}#`, 'g'),
      replacement: replacement,
      text: text,
    })
  )
}

const addPositionsReplacements = (
  replacements,
  position,
  index,
  positionName,
  currencyCode
) => {
  const positionType = positionName.toUpperCase()
  const replacePriceName = `TS_${positionType}_PRICE_${index + 1}`
  const replaceSizeName = `TS_${positionType}_SIZE_${index + 1}`
  replacements.push({
    key: replacePriceName,
    text: `${positionName} price ${index + 1}`,
    search: new RegExp(`#${replacePriceName}#`, 'g'),
    replacement:
      position && position.price
        ? currencyCode //use currency if we have it
          ? `${position.price} ${currencyCode}`
          : position.price
        : '',
    classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
  })
  replacements.push({
    key: replaceSizeName,
    text: `${positionName} size ${index + 1}`,
    search: new RegExp(`#${replaceSizeName}#`, 'g'),
    replacement: position && position.size ? position.size : '',
    classes: [CLASS_CREATED, CLASS_UPDATED, CLASS_CLOSED],
  })
  return replacements
}

const getReplacements = (signal, websiteUrl, isPreview) =>
  getPlaceholders(CLASS_ALL, signal, websiteUrl, isPreview)
const title = getPlaceholders(CLASS_TITLE)
const signalCreated = getPlaceholders(CLASS_CREATED)
const signalUpdated = getPlaceholders(CLASS_UPDATED)
const signalClosed = getPlaceholders(CLASS_CLOSED)
const signalPreview = getPlaceholders(CLASS_PREVIEW)
const photoUrl = getPlaceholders(CLASS_PHOTO_URL)

const getPreviewReplacements = (signal, websiteUrl) =>
  getReplacements(signal, websiteUrl, true)

const replaceWordpressConnectorPlaceholders = (
  wordpressConnector,
  replacements,
  connectorDataType
) => ({
  ...wordpressConnector,
  [connectorDataType]: {
    ...wordpressConnector[connectorDataType],
    title: replaceWithReplacements(
      wordpressConnector[connectorDataType].title,
      replacements
    ),
    body: replaceWithReplacements(
      wordpressConnector[connectorDataType].body,
      replacements
    ),
  },
})
const prepareText = (
  textWithPlaceholders,
  replacements,
  allowParagraphs = false
) => {
  //replace placeholders with real data
  const text = replaceWithReplacements(textWithPlaceholders, replacements)
  //we have to remove paragraphs that are used for UI (WYSIWYG editor) to function properly
  return Sanitization.Html.telegramMarkupSanitization(text, allowParagraphs)
}
const replaceTelegramConnectorPlaceholders = (
  telegramConnector,
  replacements,
  connectorDataType
) => ({
  ...telegramConnector,
  [connectorDataType]: {
    ...telegramConnector[connectorDataType],
    sendMessage: {
      ...telegramConnector[connectorDataType].sendMessage,
      message: prepareText(
        telegramConnector[connectorDataType].sendMessage.message,
        replacements,
        true
      ),
    },
    sendPhoto: {
      ...telegramConnector[connectorDataType].sendPhoto,
      caption: prepareText(
        telegramConnector[connectorDataType].sendPhoto.caption,
        replacements,
        true
      ),
      photoUrl: prepareText(
        telegramConnector[connectorDataType].sendPhoto.photoUrl,
        replacements,
        true
      ),
    },
  },
})
export default {
  Placeholders: PLACEHOLDERS,
  getReplacements,
  getPreviewReplacements,
  replaceWithReplacements,
  replaceTelegramConnectorPlaceholders,
  replaceWordpressConnectorPlaceholders,
  prepareText,
  title,
  signalCreated,
  signalUpdated,
  signalClosed,
  signalPreview,
  photoUrl,
  MAX_POSITIONS,
}
