import React from 'react'
import PropTypes from 'prop-types'

const LinkExternal = ({ to, children, ...rest }) => {
  return (
    <a href={to} {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

LinkExternal.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default LinkExternal
