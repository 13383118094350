import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { toAbsoluteUrl } from '../../_helpers'

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: '33%',
    left: '50%',
  },
  child: {
    display: 'block',
    margin: '25px auto',
  },
}))
export function LoadingIndicator() {
  // noinspection JSValidateTypes
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <img
        className={classes.child}
        src={toAbsoluteUrl('/media/logos/logo-dark.png')}
        alt="TradingSignally logo"
      />
      <CircularProgress
        className={`splash-spinner ${classes.child}`}
        color="secondary"
      />
    </div>
  )
}
