import * as yup from 'yup'
import SignalPlaceholders from '../signals/form/placeholders'

const Placeholders = SignalPlaceholders.Placeholders

//TODO: finish custom validation messages, move it somewhere else?
yup.setLocale({
  mixed: {
    default: 'default mixed locale missing',
  },
  // string: {
  //     required: intl.formatMessage({id: "SIGNALS.VALIDATION.REQUIRED_FIELD"}),
  //     max: intl.formatMessage({id: "SIGNALS.VALIDATION.TOO_LONG"}),
  //     // max: 'some text ${min}', //TODO: can we inject vars into formatMessage?
  // },
  // number: {
  //     typeError: 'must be a number',
  //     required: intl.formatMessage({id: "SIGNALS.VALIDATION.REQUIRED_FIELD"}),
  // },
})

//CUSTOM VALIDATION
// noinspection JSPotentiallyInvalidConstructorUsage
/**
 * ARRAY OF OBJECT PROPERTY VALUES SUM IS EQUAL TO
 * make sure that sum of all array -> object -> property is X
 * @param message
 * @param mapper
 * @param sum
 * @return {*}
 */
yup.array.prototype.sumOfPropertyValuesOfObjectsInArrayIs = function (
  message,
  mapper = (a) => a,
  sum
) {
  // noinspection JSUnresolvedFunction
  return this.test(
    'sumOfPropertyValuesOfObjectsInArrayIs',
    message,
    (list) => list.reduce((acc, item) => acc + mapper(item), 0) === sum,
    sum
  )
}
// noinspection JSPotentiallyInvalidConstructorUsage
/**
 * ARRAY OF OBJECT PROPERTY VALUES UNIQUE
 * makes sure that array keys contain unique values
 * @param message
 * @param mapper
 * @return {*}
 */
yup.array.prototype.propertyValuesOfObjectsInArrayAreUnique = function (
  message,
  mapper = (a) => a
) {
  // noinspection JSUnresolvedFunction
  return this.test(
    'propertyValuesOfObjectsInArrayAreUnique',
    message,
    (list) => list.length === new Set(list.map(mapper)).size
  )
}

/**
 * URL OR PLACEHOLDER
 */
const photoUrlPlaceholder = Placeholders.TS_CUSTOM_PHOTO_URL
const urlOrPlaceholderRegex = new RegExp(
  `^(#${photoUrlPlaceholder}#|((https?):\\/\\/)?(www.)?[a-z0-9]+(\\.[a-z]{2,}){1,3}(#?\\/?[a-zA-Z0-9#]+)*\\/?(\\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?)$`
)
const urlOrPlaceholderMessage = `Must be a valid URL or placeholder (#${photoUrlPlaceholder}#).`
yup.string.prototype.urlOrPlaceholder = function () {
  // noinspection JSUnresolvedFunction
  return this.test('urlOrPlaceholder', urlOrPlaceholderMessage, (value) =>
    urlOrPlaceholderRegex.test(value)
  )
}

export default yup
