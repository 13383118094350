import React from 'react'
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'
import '@formatjs/intl-relativetimeformat/dist/locale-data/de'
import '@formatjs/intl-relativetimeformat/dist/locale-data/es'
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr'
import '@formatjs/intl-relativetimeformat/dist/locale-data/ja'
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh'

import {i18nMessages as allMessages} from '@tad3j/shared'
//old metronic code
// import enMessages from './messages/en'
// const allMessages = {
//   en: enMessages,
// }

export function I18nProvider({ children }) {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}
