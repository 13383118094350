import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { TextField } from '@material-ui/core'
import * as auth from '../../../redux/ducks/auth.duck'
import { CustomPropTypes } from '@tad3j/shared'
import PropTypes from 'prop-types'
import { loginValidationSchema } from '@tad3j/shared'
import { Helmet } from 'react-helmet'

function Login({ intl, login }) {
  const [loading, setLoading] = useState(false)

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const handleFormSubmit = (values, { setStatus }) => {
    const onError = (message) => {
      disableLoading()
      setStatus(intl.formatMessage(message))
    }

    const onSuccess = () => {
      disableLoading()
    }

    //perform login
    enableLoading()
    login(values, onSuccess, onError)
  }

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <div className="login-form login-signin">
        {/* begin::Head */}
        <div className="text-center mb-5 mb-lg-10">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">
            Enter your username and password
          </p>
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={handleFormSubmit}
          validationSchema={loginValidationSchema()}
        >
          {({ values, status, errors, touched, handleChange, handleBlur }) => (
            <Form
              noValidate={true}
              autoComplete="off"
              className="form fv-plugins-bootstrap fv-plugins-framework"
            >
              {
                status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{status}</div>
                  </div>
                ) /*(
                <div
                  role="alert"
                  className="mb-10 alert alert-custom alert-light-info alert-dismissible"
                >
                  <div className="alert-text">
                    <p>
                      Web platform is currently{' '}
                      <strong>only available for PUBLISHERS</strong>, other
                      users should use mobile app.
                    </p>
                  </div>
                </div>
              )*/
              }

              <div className="form-group fv-plugins-icon-container">
                <TextField
                  variant="filled"
                  type="email"
                  label="Email"
                  className="form-control form-control-solid h-auto"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  variant="filled"
                  label="Password"
                  className="form-control form-control-solid h-auto"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                <Link
                  to="/auth/forgot-password"
                  className="text-dark-50 text-hover-primary my-3 mr-2"
                >
                  <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                </Link>

                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                >
                  <span>Sign In</span>
                  {loading && <span className="ml-3 spinner spinner-white" />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {/*end::Form*/}
      </div>
    </>
  )
}

Login.propTypes = {
  intl: CustomPropTypes.intl,
  login: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  login: auth.actions.login,
}

export default injectIntl(connect(null, mapDispatchToProps)(Login))
