/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy, useEffect } from 'react'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { Layout, LayoutSplashScreen } from '../_metronic/layout'
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage'
import { AuthPage } from './pages/auth/AuthPage'
import Logout from './pages/auth/Logout'
import { analytics } from '../services/firebase'
const BasePageLazy = lazy(() => import('./BasePage'))

export function Routes() {
  const { isLoading, isAuthorized } = useSelector(
    ({ auth }) => ({
      isLoading: auth.loading === true,
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  )
  usePageViews(isLoading)
  if (isLoading) {
    return <LayoutSplashScreen />
  }
  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePageLazy />
        </Layout>
      )}
    </Switch>
  )
}

function usePageViews(isLoading) {
  const location = useLocation()

  useEffect(() => {
    if (!isLoading) {
      analytics.setCurrentScreen(location.pathname)
    }
  }, [isLoading, location])
}
