function findConnectorData(connectors, type, dataType) {
  const connector = connectors.find((c) => c.integration.resource.type === type)
  if (!connector) {
    return null
  }
  return {
    [dataType]: connector[dataType],
    integration: connector.integration,
  }
}

function getConnectorData(connector, dataType) {
  return {
    [dataType]: connector[dataType],
    integration: connector.integration,
  }
}

export default { findConnectorData, getConnectorData }
