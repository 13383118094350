import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import FormErrorAlert from '../content/FormErrorAlert'
import FormSubmitButton from '../content/FormSubmitButton'
import { actions as userActions } from '../../../redux/ducks/users.duck'
import { connect } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { ContactForm } from '@tad3j/shared'
import config from '../../../config'
import TransitionUp from './TransitionUp'
import DialogTitleWithCloseIcon from './components/DialogTitleWithCloseIcon'

const recaptchaRef = React.createRef()

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    marginTop: theme.spacing(1),
  },
  btn: {
    marginRight: theme.spacing(1),
  },
}))

const ContactFormDialog = ({
  linkText,
  contactFormSubmit,
  className,
  ...rest
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [sent, setSent] = React.useState(false)
  function handleClickOpen() {
    setOpen(true)
    setSent(false)
  }
  function handleClose() {
    setOpen(false)
  }
  const handleFormSubmit = async (values, { setStatus }) => {
    setStatus(null)
    const token = await recaptchaRef.current.executeAsync()
    const onError = (e) => {
      console.error(e)
      setLoading(false)
      setStatus(e.message)
    }
    const onSuccess = () => {
      setLoading(false)
      setSent(true)
    }
    setLoading(true)
    contactFormSubmit(
      values.from,
      values.subject,
      values.message,
      token,
      onSuccess,
      onError
    )
  }

  return (
    <>
      <Link
        to="#"
        rel="noopener noreferrer"
        className={className}
        onClick={handleClickOpen}
      >
        {linkText}
      </Link>
      <Dialog
        disableBackdropClick
        fullWidth
        fullScreen={fullScreen}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionUp}
        {...rest}
      >
        <DialogTitleWithCloseIcon dialogClose={handleClose} disabled={loading}>
          {sent ? 'Contact Email Sent' : 'Contact Us'}
        </DialogTitleWithCloseIcon>
        <DialogContent>
          {sent ? (
            <>
              <p>Thank you for contacting us! </p>
              <p>We&apos;ll get back to you as soon as possible.</p>
              <DialogActions>
                <Button color="primary" onClick={handleClose}>
                  Close
                </Button>
              </DialogActions>
            </>
          ) : (
            <Formik
              initialValues={{
                from: '',
                subject: '',
                message: '',
              }}
              onSubmit={handleFormSubmit}
              validationSchema={ContactForm.validationSchema}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
              }) => (
                <Form className="form" noValidate={true} autoComplete="off">
                  <FormErrorAlert status={status} />
                  <div className="form-group">
                    <TextField
                      margin="normal"
                      label="From"
                      name="from"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.from}
                      helperText={touched.from && errors.from}
                      error={Boolean(touched.from && errors.from)}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      margin="normal"
                      label="Subject"
                      name="subject"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.subject}
                      helperText={touched.subject && errors.subject}
                      error={Boolean(touched.subject && errors.subject)}
                    />
                  </div>

                  <div className="form-group">
                    <TextField
                      margin="normal"
                      label="Message"
                      name="message"
                      multiline
                      required
                      rows={5}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.message}
                      helperText={touched.message && errors.message}
                      error={Boolean(touched.message && errors.message)}
                    />
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={config.recaptcha.siteKey}
                  />
                  <DialogActions>
                    <FormSubmitButton
                      variant="contained"
                      color="primary"
                      className={classes.btn}
                      loading={loading}
                      disabled={loading}
                    >
                      {loading ? 'Sending...' : 'Send'}
                    </FormSubmitButton>
                    <Button color="primary" onClick={handleClose}>
                      Close
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

ContactFormDialog.propTypes = {
  linkText: PropTypes.string.isRequired,
  contactFormSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const mapDispatchToProps = {
  contactFormSubmit: userActions.contactFormSubmit,
}

export default connect(null, mapDispatchToProps)(ContactFormDialog)
