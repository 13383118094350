import React from 'react'
import { Link, Switch, Redirect } from 'react-router-dom'
import { checkIsActive, toAbsoluteUrl } from '../../../_metronic/_helpers'
import { ContentRoute } from '../../../_metronic/layout'
import Login from './Login'
import Registration from './Registration'
import ForgotPassword from './ForgotPassword'
import '../../../_metronic/_assets/sass/pages/login/classic/login-1.scss'
import GeneralDialog from '../../partials/dialogs/GeneralDialog'
import PrivacyPolicy from '../../partials/policies/PrivacyPolicy'
import TermsAndConditions from '../../partials/policies/TermsAndConditions'
import ContactFormDialog from '../../partials/dialogs/ContactFormDialog'
import TelegramIcon from '../../partials/icons/logos/TelegramIcon'
import WordpressIcon from '../../partials/icons/logos/WordpressIcon'
import AndroidIcon from '../../partials/icons/logos/AndroidIcon'
import LinkExternal from '../../partials/components/LinkExternal'
import { Info } from '@tad3j/shared'
import AvatarTransparent from '../../partials/icons/components/AvatarTransparent'
import { useLocation } from 'react-router'

export function AuthPage() {
  const location = useLocation()
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white">
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl('/media/bg/bg-4.jpg')})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl('/media/logos/logo-letter-1.png')}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  TradingSignally
                </h3>
                <h6 className="font-weight-lighter text-white opacity-80">
                  Platform for <b>management</b>, <b>distribution</b> &{' '}
                  <b>delivery</b> of trading signals.
                </h6>
                <div className="d-flex flew-column justify-content-center align-content-center p-3">
                  <div className="d-flex flex-row justify-content-center align-content-center">
                    <AvatarTransparent className="opacity-40 m-2 font-size-h1">
                      <TelegramIcon />
                    </AvatarTransparent>
                    <span className="text-white text-dark-25 p-2">
                      Telegram Integration
                    </span>
                  </div>
                  <div className="d-flex flex-row justify-content-center">
                    <AvatarTransparent className="opacity-80 m-2 font-size-h1">
                      <WordpressIcon />
                    </AvatarTransparent>
                    <span className="text-white text-dark-25 p-2">
                      Wordpress Integration
                    </span>
                  </div>
                  <div>
                    <LinkExternal to={Info.GOOGLE_PLAY_URL}>
                      <div className="d-flex flex-row justify-content-start">
                        <AvatarTransparent className="opacity-50 m-2 font-size-h1">
                          <AndroidIcon />
                        </AvatarTransparent>
                        <span className="text-white text-dark-25 text-hover-white p-2">
                          Android Application
                        </span>
                      </div>
                    </LinkExternal>
                  </div>
                </div>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {new Date().getFullYear()} TradingSignally
                </div>
                <div className="d-flex">
                  <GeneralDialog
                    title="Privacy Policy"
                    linkText="Privacy"
                    className="text-white"
                    keepMounted
                    ariaName="privacy-policy"
                  >
                    <PrivacyPolicy />
                  </GeneralDialog>
                  <GeneralDialog
                    title="Terms & Conditions"
                    linkText="Terms & Conditions"
                    className="text-white ml-5"
                    keepMounted
                    ariaName="terms-and-conditions"
                  >
                    <TermsAndConditions />
                  </GeneralDialog>
                  <ContactFormDialog
                    linkText="Contact"
                    className="text-white ml-5"
                    keepMounted
                  />
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              {!checkIsActive(location, '/auth/registration') && (
                <Link to="/auth/registration" className="font-weight-bold ml-2">
                  Sign Up for FREE!
                </Link>
              )}
            </div>
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {new Date().getFullYear()} TradingSignally
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  )
}
