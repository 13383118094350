import * as yup from 'yup'
import { displayName } from './common'
import FieldNames from '../fields'

export const schemaSpec = {
  [FieldNames.DISPLAY_NAME]: displayName(),
}
export default function validationSchema() {
  return yup.object(schemaSpec)
}
