const USER_GROUP_LIMIT = 10

const logoSupportedExtensions = ['png', 'jpg', 'jpeg' /*, 'image/gif'*/]
const minLogoDimension = 400
const LOGO_VALIDATION = {
  SUPPORTED_FORMATS: logoSupportedExtensions.map((val) => `image/${val}`),
  MAX_FILE_SIZE_IN_MB: 10,
  MIN_IMAGE_WIDTH: minLogoDimension,
  MIN_IMAGE_HEIGHT: minLogoDimension,
  SUPPORTED_EXTENSIONS: logoSupportedExtensions,
}

/**
 * Determines if group is private
 * @param {{visibility: {string}}} group
 * @returns {boolean}
 */
const isPrivate = (group) => group && group.visibility === 'private'

/**
 * Determines if group is public
 * @param {{visibility: {string}}} group
 * @returns {boolean}
 */
const isPublic = (group) => group && group.visibility === 'public'

const mergeUserGroupIds = (signalGroupsFollowing, privateGroupMembership) => {
  let userGroups = []
  if (signalGroupsFollowing) {
    userGroups = userGroups.concat(signalGroupsFollowing)
  }
  if (privateGroupMembership) {
    userGroups = userGroups.concat(privateGroupMembership)
  }
  return userGroups
}

export default {
  isPrivate,
  isPublic,
  mergeUserGroupIds,
  USER_GROUP_LIMIT,
  LOGO_VALIDATION,
}
