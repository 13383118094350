import * as yup from 'yup'

const integration = yup.object({
  id: yup.string().trim().required(),
  resource: yup.object({
    type: yup
      .string()
      .trim()
      .required()
      .matches(
        /^(wordpress|telegram)$/,
        'Integration should be one of the following: wordpress, telegram'
      ),
  }),
})

export default { integration }
