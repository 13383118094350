import yup from '../../../../../yup'
import ActionTypes from '../../action-types'

const photoUrlSchema = yup.string().max(255).required()

export const connectorDataValidator = (placeholdersAllowed = false) =>
  yup.object({
    enabled: yup.boolean().required(),
    sendType: yup
      .string()
      .matches(/^(sendMessage|sendPhoto)$/)
      .required(),
    sendMessage: yup.object().when(['enabled', 'sendType'], {
      is: (enabled, sendType) =>
        enabled && sendType === ActionTypes.SEND_MESSAGE,
      then: yup
        .object({
          message: yup.string().trim().max(4096).required(),
          disableWebPagePreview: yup.bool().required(),
        })
        .required(),
      otherwise: yup.object().nullable(),
    }),
    sendPhoto: yup.object().when(['enabled', 'sendType'], {
      is: (enabled, sendType) => enabled && sendType === ActionTypes.SEND_PHOTO,
      then: yup
        .object({
          photoUrl: placeholdersAllowed
            ? photoUrlSchema.urlOrPlaceholder()
            : photoUrlSchema.url(),
          caption: yup.string().trim().max(1024).required(),
        })
        .required(),
      otherwise: yup.object().default(null).nullable(),
    }),
  })

const basePostValidatorObj = (fieldNamePrefix) =>
  yup
    .object({
      signalCreated: connectorDataValidator(true),
      signalUpdated: connectorDataValidator(true),
      signalClosed: connectorDataValidator(true),
    })
    .required()
    .test('atLeastOneIsEnabled', null, (obj) => {
      let atLeastOneEnabled = false
      const sections = Object.keys(obj)
      for (let i = 0; i < sections.length; i++) {
        if (obj[sections[i]].enabled) {
          atLeastOneEnabled = true
          break
        }
      }
      if (atLeastOneEnabled) {
        return true // everything is fine
      }

      return new yup.ValidationError(
        'at least one of the events needs to be enabled:',
        null,
        `${fieldNamePrefix ? fieldNamePrefix : ''}atLeastOneIsEnabled`
      )
    })

export default basePostValidatorObj
