import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const WordpressIcon = ({ className, ...props }) => {
  return <span {...props} className={clsx('socicon-wordpress', className)} />
}
WordpressIcon.propTypes = {
  className: PropTypes.string,
}
export default WordpressIcon
