import {
  all,
  call,
  fork,
  select,
  put,
  take,
  takeLatest,
  cancel,
} from 'redux-saga/effects'
import firebase, { analytics, functions } from '../../services/firebase'
import ReduxSagaFirebase from 'redux-saga-firebase'
import { FirestoreHelpers, ReduxSelectors } from '@tad3j/shared'
import { authActionTypes } from './auth.duck'
import { update } from 'lodash/fp'

const rsf = new ReduxSagaFirebase(firebase)
const FieldValue = firebase.firestore.FieldValue
const FieldPath = firebase.firestore.FieldPath

const typeName = 'SIGNAL_GROUPS'
export const actionTypes = {
  SIGNAL_GROUPS: {
    CREATE: `${typeName}.CREATE`,
    CREATE_SUCCESS: `${typeName}.CREATE_SUCCESS`,
    CREATE_FAILURE: `${typeName}.CREATE_FAILURE`,
    SYNC_REQUEST: `${typeName}.SYNC_REQUEST`,
    SYNC_SUCCESS: `${typeName}.SYNC_SUCCESS`,
    SYNC_FAILURE: `${typeName}.SYNC_FAILURE`,
    APPROVE_MEMBERSHIP_REQUESTS_REQUEST: `${typeName}.APPROVE_MEMBERSHIP_REQUESTS_REQUEST`,
    APPROVE_MEMBERSHIP_REQUESTS_SUCCESS: `${typeName}.APPROVE_MEMBERSHIP_REQUESTS_SUCCESS`,
    APPROVE_MEMBERSHIP_REQUESTS_FAILURE: `${typeName}.APPROVE_MEMBERSHIP_REQUESTS_FAILURE`,
    REJECT_MEMBERSHIP_REQUESTS_REQUEST: `${typeName}.REJECT_MEMBERSHIP_REQUESTS_REQUEST`,
    REJECT_MEMBERSHIP_REQUESTS_SUCCESS: `${typeName}.REJECT_MEMBERSHIP_REQUESTS_SUCCESS`,
    REJECT_MEMBERSHIP_REQUESTS_FAILURE: `${typeName}.REJECT_MEMBERSHIP_REQUESTS_FAILURE`,
    REMOVE_MEMBERS_REQUEST: `${typeName}.REMOVE_MEMBERS_REQUEST`,
    REMOVE_MEMBERS_SUCCESS: `${typeName}.REMOVE_MEMBERS_SUCCESS`,
    REMOVE_MEMBERS_FAILURE: `${typeName}.REMOVE_MEMBERS_FAILURE`,
    DELETE: `${typeName}.DELETE`,
    //public
    SUBSCRIBE_USER_REQUEST: `${typeName}.PUBLIC.SUBSCRIBE_USER_REQUEST`,
    SUBSCRIBE_USER_SUCCESS: `${typeName}.PUBLIC.SUBSCRIBE_USER_SUCCESS`,
    SUBSCRIBE_USER_FAILURE: `${typeName}.PUBLIC.SUBSCRIBE_USER_FAILURE`,
    UNSUBSCRIBE_USER_REQUEST: `${typeName}.PUBLIC.UNSUBSCRIBE_USER_REQUEST`,
    UNSUBSCRIBE_USER_SUCCESS: `${typeName}.PUBLIC.UNSUBSCRIBE_USER_SUCCESS`,
    UNSUBSCRIBE_USER_FAILURE: `${typeName}.PUBLIC.UNSUBSCRIBE_USER_FAILURE`,
    //private
    REQUEST_ACCESS_REQUEST: `${typeName}.PRIVATE.REQUEST_ACCESS_REQUEST`,
    REQUEST_ACCESS_SUCCESS: `${typeName}.PRIVATE.REQUEST_ACCESS_SUCCESS`,
    REQUEST_ACCESS_FAILURE: `${typeName}.PRIVATE.REQUEST_ACCESS_FAILURE`,
    REVOKE_ACCESS_REQUEST: `${typeName}.PRIVATE.REVOKE_ACCESS_REQUEST`,
    REVOKE_ACCESS_SUCCESS: `${typeName}.PRIVATE.REVOKE_ACCESS_SUCCESS`,
    REVOKE_ACCESS_FAILURE: `${typeName}.PRIVATE.REVOKE_ACCESS_FAILURE`,
  },
  SIGNAL_GROUP_SECRETS: {
    SYNC: 'SIGNAL_GROUP_SECRETS.SYNC_SUCCESS',
    SYNC_FAILURE: 'SIGNAL_GROUP_SECRETS.SYNC_FAILURE',
    CREATE_GROUP_CONNECTOR_REQUEST:
      'SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_REQUEST',
    CREATE_GROUP_CONNECTOR_SUCCESS:
      'SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_SUCCESS',
    CREATE_GROUP_CONNECTOR_FAILURE:
      'SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_FAILURE',
    EDIT_GROUP_CONNECTOR_REQUEST:
      'SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_REQUEST',
    EDIT_GROUP_CONNECTOR_SUCCESS:
      'SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_SUCCESS',
    EDIT_GROUP_CONNECTOR_FAILURE:
      'SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_FAILURE',
    DELETE_GROUP_CONNECTOR_REQUEST:
      'SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_REQUEST',
    DELETE_GROUP_CONNECTOR_SUCCESS:
      'SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_SUCCESS',
    DELETE_GROUP_CONNECTOR_FAILURE:
      'SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_FAILURE',
  },
}

const initialState = {
  list: [],
  newSignalGroup: undefined,
  isRequesting: false,
}

function createSignalGroupData(uid, newSignalGroup, now) {
  return {
    ...newSignalGroup,
    //beside group details, we also have to add some systemic values
    owner: uid,
    createdAt: now,
    updatedAt: now,
  }
}

export const reducer = function (state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SIGNAL_GROUPS.CREATE:
      const newSignalGroup = createSignalGroupData(
        action.uid,
        action.newSignalGroup,
        firebase.firestore.Timestamp.now().toMillis()
      )
      return {
        ...state,
        newSignalGroup,
      }
    case actionTypes.SIGNAL_GROUPS.CREATE_SUCCESS:
      return {
        ...state,
        newSignalGroup: null,
      }
    case actionTypes.SIGNAL_GROUPS.SYNC_SUCCESS:
      return {
        ...state,
        list: action.signalGroups,
      }
    case actionTypes.SIGNAL_GROUP_SECRETS.SYNC:
      return update(
        'list',
        (signalGroups) =>
          signalGroups.map((sg) =>
            sg
              ? {
                  ...sg,
                  ...action.signalGroupSecrets.find((sgs) => sgs.id === sg.id),
                }
              : sg
          ),
        state
      )
    case actionTypes.SIGNAL_GROUPS.APPROVE_MEMBERSHIP_REQUESTS_SUCCESS:
      return {
        ...state,
        list: state.list.map((group) => {
          if (group.id === action.groupId) {
            const membersDetails = group.membersDetails
              ? group.membersDetails
              : {}
            action.usersData.forEach(({ id, email, createdAt }) => {
              if (group.members) {
                group.members.push(id)
              } else {
                group.members = [id]
              }
              membersDetails[id] = { email, createdAt }
            })
            group.membersDetails = membersDetails
          }
          return group
        }),
      }
    case actionTypes.SIGNAL_GROUPS.REJECT_MEMBERSHIP_REQUESTS_SUCCESS:
      return {
        ...state,
        list: state.list.map((group) => {
          if (group.id === action.groupId) {
            if (group.membershipRequests) {
              action.usersIds.forEach((uid) => {
                delete group.membershipRequests[uid]
              })
            }
          }
          return group
        }),
      }
    case actionTypes.SIGNAL_GROUPS.REMOVE_MEMBERS_SUCCESS:
      return {
        ...state,
        list: state.list.map((group) => {
          if (group.id === action.groupId) {
            if (group.members) {
              group.members = group.members.filter(
                (uid) => !action.usersIds.includes(uid)
              )
            }
            if (group.membershipRequests) {
              action.usersIds.forEach((uid) => {
                delete group.membershipRequests[uid]
              })
            }
          }
          return group
        }),
      }
    // case actionTypes.SIGNAL_GROUPS.DELETE:
    //     return {
    //         ...state,
    //         signalGroups: state.list.filter(signalGroup => signalGroup.id !== action.signalGroupId ),
    //     };
    case actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_REQUEST:
      return {
        ...state,
        isRequesting: true,
      }
    case actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
      }
    case actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_FAILURE:
      return {
        ...state,
        isRequesting: false,
      }
    case actionTypes.SIGNAL_GROUPS.UNSUBSCRIBE_USER_REQUEST:
      return {
        ...state,
        isRequesting: true,
      }
    case actionTypes.SIGNAL_GROUPS.UNSUBSCRIBE_USER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
      }
    case actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      }
    case actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
      }
    case actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_FAILURE:
      return {
        ...state,
        isRequesting: false,
      }
    case actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_REQUEST:
      return {
        ...state,
        isRequesting: true,
      }
    case actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
      }
    case actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_FAILURE:
      return {
        ...state,
        isRequesting: false,
      }
    default:
      return state
  }
}

export const actions = {
  createSignalGroup: (newSignalGroup, uid, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUPS.CREATE,
    newSignalGroup,
    uid,
    onSuccess,
    onError,
  }),
  createSignalGroupSuccess: (gid, dynamicLinkUrl) => ({
    type: actionTypes.SIGNAL_GROUPS.CREATE_SUCCESS,
    gid,
    dynamicLinkUrl,
  }),
  createSignalGroupFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUPS.CREATE_FAILURE,
    error,
  }),
  approveMembershipRequestsRequest: (
    groupId,
    usersData,
    onSuccess,
    onError
  ) => ({
    type: actionTypes.SIGNAL_GROUPS.APPROVE_MEMBERSHIP_REQUESTS_REQUEST,
    groupId,
    usersData,
    onSuccess,
    onError,
  }),
  approveMembershipRequestsSuccess: (groupId, usersData) => ({
    type: actionTypes.SIGNAL_GROUPS.APPROVE_MEMBERSHIP_REQUESTS_SUCCESS,
    groupId,
    usersData,
  }),
  approveMembershipRequestsFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUPS.APPROVE_MEMBERSHIP_REQUESTS_FAILURE,
    error,
  }),
  rejectMembershipRequestsRequest: (groupId, usersIds, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUPS.REJECT_MEMBERSHIP_REQUESTS_REQUEST,
    groupId,
    usersIds,
    onSuccess,
    onError,
  }),
  rejectMembershipRequestsSuccess: (groupId, usersIds) => ({
    type: actionTypes.SIGNAL_GROUPS.REJECT_MEMBERSHIP_REQUESTS_SUCCESS,
    groupId,
    usersIds,
  }),
  rejectMembershipRequestsFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUPS.REJECT_MEMBERSHIP_REQUESTS_FAILURE,
    error,
  }),
  removeMembersRequest: (groupId, usersIds, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUPS.REMOVE_MEMBERS_REQUEST,
    groupId,
    usersIds,
    onSuccess,
    onError,
  }),
  removeMembersSuccess: (groupId, usersIds) => ({
    type: actionTypes.SIGNAL_GROUPS.REMOVE_MEMBERS_SUCCESS,
    groupId,
    usersIds,
  }),
  removeMembersFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUPS.REMOVE_MEMBERS_FAILURE,
    error,
  }),
  syncSignalGroupsRequest: () => ({
    type: actionTypes.SIGNAL_GROUPS.SYNC_REQUEST,
  }),
  syncSignalGroupsSuccess: (signalGroups) => ({
    type: actionTypes.SIGNAL_GROUPS.SYNC_SUCCESS,
    signalGroups,
  }),
  syncSignalGroupsFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUPS.SYNC_FAILURE,
    error,
  }),
  syncSignalGroupSecrets: (signalGroupSecrets) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.SYNC,
    signalGroupSecrets,
  }),
  syncSignalGroupSecretsFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.SYNC_FAILURE,
    error,
  }),
  createGroupConnector: (
    userId,
    groupId,
    integrationId,
    connectorData,
    onSuccess,
    onError
  ) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_REQUEST,
    userId,
    groupId,
    integrationId,
    connectorData,
    onSuccess,
    onError,
  }),
  createGroupConnectorSuccess: (groupId, connectorId) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_SUCCESS,
    groupId,
    connectorId,
  }),
  createGroupConnectorFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_FAILURE,
    error,
  }),
  editGroupConnector: (
    groupId,
    connectorId,
    connectorData,
    onSuccess,
    onError
  ) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_REQUEST,
    groupId,
    connectorId,
    connectorData,
    onSuccess,
    onError,
  }),
  editGroupConnectorSuccess: (groupId, connectorId) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_SUCCESS,
    groupId,
    connectorId,
  }),
  editGroupConnectorFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_FAILURE,
    error,
  }),
  deleteGroupConnector: (groupId, connectorId, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_REQUEST,
    groupId,
    connectorId,
    onSuccess,
    onError,
  }),
  deleteGroupConnectorSuccess: (groupId, connectorId) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_SUCCESS,
    groupId,
    connectorId,
  }),
  deleteGroupConnectorFailure: (error) => ({
    type: actionTypes.SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_FAILURE,
    error,
  }),

  //PUBLIC
  subscribeUser: (uid, group, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_REQUEST,
    uid,
    group,
    onSuccess,
    onError,
  }),
  subscribeUserSuccess: (uid, group) => ({
    type: actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_SUCCESS,
    uid,
    group,
  }),
  subscribeUserFailure: (e) => ({
    type: actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_FAILURE,
    e,
  }),
  unsubscribeUser: (uid, gid, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUPS.UNSUBSCRIBE_USER_REQUEST,
    uid,
    gid,
    onSuccess,
    onError,
  }),
  unsubscribeUserSuccess: (uid, gid) => ({
    type: actionTypes.SIGNAL_GROUPS.UNSUBSCRIBE_USER_SUCCESS,
    uid,
    gid,
  }),
  unsubscribeUserFailure: (gid, e) => ({
    type: actionTypes.SIGNAL_GROUPS.UNSUBSCRIBE_USER_FAILURE,
    gid,
    e,
  }),

  //PRIVATE
  requestAccess: (uid, userEmail, gid, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_REQUEST,
    uid,
    userEmail,
    gid,
    onSuccess,
    onError,
  }),
  requestAccessSuccess: (uid, userEmail, gid) => ({
    type: actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_SUCCESS,
    uid,
    userEmail,
    gid,
  }),
  requestAccessFailure: (gid, error) => ({
    type: actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_FAILURE,
    gid,
    error,
  }),
  revokeAccess: (uid, gid, onSuccess, onError) => ({
    type: actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_REQUEST,
    uid,
    gid,
    onSuccess,
    onError,
  }),
  revokeAccessSuccess: (uid, gid) => ({
    type: actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_SUCCESS,
    uid,
    gid,
  }),
  revokeAccessFailure: (gid, error) => ({
    type: actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_FAILURE,
    gid,
    error,
  }),
}

function* createSignalGroupSaga({ newSignalGroup, onSuccess, onError }) {
  // const now = firebase.firestore.Timestamp.now()
  try {
    // const newSignalGroup = createSignalGroupData(
    //   action.uid,
    //   action.newSignalGroup,
    //   now
    // )
    const createSignalGroupRequest = functions.httpsCallable(
      'createSignalGroupRequest'
    )
    const { data } = yield call(createSignalGroupRequest, newSignalGroup)
    yield put(actions.createSignalGroupSuccess(data.gid))
    onSuccess(data.gid)
  } catch (e) {
    yield put(actions.createSignalGroupFailure(e))
    onError(e)
  }
}

function* approveMembershipRequestsSaga({
  groupId,
  usersData,
  onSuccess,
  onError,
}) {
  try {
    const usersIds = usersData.map((user) => user.id)
    const promoteUsersToGroupMembers = functions.httpsCallable(
      'promoteUsersToGroupMembers'
    )
    const result = yield call(promoteUsersToGroupMembers, { groupId, usersIds })
    onSuccess(result.data)
    yield put(actions.approveMembershipRequestsSuccess(groupId, usersData))
  } catch (e) {
    yield put(actions.approveMembershipRequestsFailure(e))
    onError(e.message)
  }
}

const removeUsersMembership = functions.httpsCallable('removeUsersMembership')

function* removeMembershipRequestsSaga({
  groupId,
  usersIds,
  onSuccess,
  onError,
}) {
  try {
    const result = yield call(removeUsersMembership, {
      groupId,
      usersIds,
    })

    yield put(actions.rejectMembershipRequestsSuccess(groupId, usersIds))
    onSuccess(result.data)
  } catch (e) {
    yield put(actions.rejectMembershipRequestsFailure(e))
    onError(e.message)
  }
}

function* removeMembersSaga({ groupId, usersIds, onSuccess, onError }) {
  try {
    const result = yield call(removeUsersMembership, {
      groupId,
      usersIds,
    })
    yield put(actions.removeMembersSuccess(groupId, usersIds))
    onSuccess(result.data)
  } catch (e) {
    yield put(actions.removeMembersFailure(e))
    onError(e.message)
  }
}

function* syncSignalGroupsSaga(/*action*/) {
  const { user, isAdmin, isPublisher } = yield select((state) => state.auth)
  const userGroups = yield select(ReduxSelectors.SignalGroups.selectUserGroups)
  const isAdminOrPublisher = isAdmin || isPublisher
  let syncSignalGroupsTask
  let colRef
  if (isAdminOrPublisher) {
    colRef = firebase
      .firestore()
      .collection('signalGroups')
      .where('owner', '==', user.uid)
      .orderBy('updatedAt', 'desc')
  } else {
    if (userGroups.length > 0) {
      colRef = firebase
        .firestore()
        .collection('signalGroups')
        .where(FieldPath.documentId(), 'in', userGroups)
    }
  }
  if (colRef) {
    // Start the sync task
    // noinspection JSCheckFunctionSignatures
    syncSignalGroupsTask = yield fork(rsf.firestore.syncCollection, colRef, {
      transform: FirestoreHelpers.receiveSignalGroups,
      successActionCreator: actions.syncSignalGroupsSuccess,
      failureActionCreator: actions.syncSignalGroupsFailure,
    })
  } else {
    actions.syncSignalGroupsSuccess([])
  }

  // Wait for the logout action, then stop sync
  yield take(authActionTypes.LOGOUT_SUCCESS)

  //cancel sync task
  if (syncSignalGroupsTask) {
    yield cancel(syncSignalGroupsTask)
  }
}

function* syncSignalGroupSecretsSaga(/*action*/) {
  const { user, isAdmin, isPublisher } = yield select((state) => state.auth)
  const isAdminOrPublisher = isAdmin || isPublisher
  let syncSignalGroupsTask
  if (isAdminOrPublisher) {
    // Start the sync task
    /**
     * @type {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}
     */
    const colRef = firebase
      .firestore()
      .collection('signalGroupSecrets')
      .where('owner', '==', user.uid)
    // .orderBy('updatedAt', 'desc')
    // noinspection JSCheckFunctionSignatures
    syncSignalGroupsTask = yield fork(rsf.firestore.syncCollection, colRef, {
      transform: FirestoreHelpers.receiveSignalGroupSecrets,
      successActionCreator: actions.syncSignalGroupSecrets,
      failureActionCreator: actions.syncSignalGroupSecretsFailure,
    })
  } else {
    yield put(actions.syncSignalGroupSecrets([]))
  }

  // Wait for the logout action, then stop sync
  yield take(authActionTypes.LOGOUT_SUCCESS)

  //cancel sync task
  if (syncSignalGroupsTask) {
    yield cancel(syncSignalGroupsTask)
  }
}

// function* deleteSignalGroup(action) {
//     yield call(rsf.firestore.deleteDocument, `signalGroups/${action.signalGroupId}`)
// }

//PUBLIC
function* subscribeUserSaga({ uid, group, onSuccess, onError }) {
  const gid = group.id
  try {
    yield manageSubscription(uid, gid, true)
    yield put(actions.subscribeUserSuccess(uid, group))
    onSuccess()
    //seems like we don't need this fro now
    // update auth store (same change as query above)
    // yield put(authActions.subscribeUser(gid))
  } catch (e) {
    yield put(actions.subscribeUserFailure(e))
    onError(e.message)
  }
}
function* unsubscribeUserSaga({ uid, gid, onSuccess, onError }) {
  try {
    yield manageSubscription(uid, gid, false)
    yield put(actions.unsubscribeUserSuccess(uid, gid))
    onSuccess()
    //seems like we don't need this fro now
    // update auth store (same change as query above)
    // yield put(authActions.unsubscribeUser(gid))
  } catch (e) {
    yield put(actions.unsubscribeUserFailure(e))
    onError(e.message)
  }
}
function* manageSubscription(uid, gid, subscribe = true) {
  const data = {
    signalGroupsFollowing: subscribe
      ? FieldValue.arrayUnion(gid)
      : FieldValue.arrayRemove(gid),
  }
  yield call(rsf.firestore.setDocument, `users/${uid}`, data, {
    merge: true,
  })
}

//PRIVATE
function* requestAccessSaga({ uid, gid, userEmail, onSuccess, onError }) {
  const data = {
    privateGroupRequests: FieldValue.arrayUnion(gid),
  }
  try {
    yield call(rsf.firestore.setDocument, `users/${uid}`, data, {
      merge: true,
    })
    //seems like we don't need this fro now
    //update auth store (same change as query above)
    // yield put(authActions.requestPrivateGroupMembership(action.gid))
    yield put(actions.requestAccessSuccess(uid, userEmail, gid))
    onSuccess()
  } catch (e) {
    yield put(actions.requestAccessFailure(e))
    onError(e.message)
  }
}
function* revokeAccessSaga({ gid, uid, onSuccess, onError }) {
  //remove groups from user
  const data = {
    privateGroupRequests: FieldValue.arrayRemove(gid),
    privateGroupMembership: FieldValue.arrayRemove(gid),
  }
  try {
    yield call(rsf.firestore.setDocument, `users/${uid}`, data, {
      merge: true,
    })
    //seems like we don't need this fro now
    //update auth store (same change as query above)
    //first we have to remove group id from privateGroupMembership, else signals channels will throw an exception because
    // they are still listening for signals but user's permission has already been removed (listeners will automatically
    // turn back on when group removal will be synced with user in DB)
    // yield put(authActions.revokePrivateGroupMembership(action.gid))
    yield put(actions.revokeAccessSuccess(uid, gid))
    onSuccess()
  } catch (e) {
    yield put(actions.revokeAccessFailure(e))
    onError(e.message)
  }
}
function* createGroupConnectorSaga({
  userId,
  groupId,
  integrationId,
  connectorData,
  onSuccess,
  onError,
}) {
  try {
    const createSignalGroupConnector = functions.httpsCallable(
      'createSignalGroupConnector'
    )
    // const { data } =
    const { data } = yield call(createSignalGroupConnector, {
      userId,
      groupId,
      integrationId,
      connectorData,
    })
    yield put(actions.createGroupConnectorSuccess(groupId, data.id))
    onSuccess()
  } catch (e) {
    yield put(actions.createGroupConnectorFailure(e))
    onError(e)
  }
}
function* editGroupConnectorSaga({
  groupId,
  connectorId,
  connectorData,
  onSuccess,
  onError,
}) {
  try {
    yield call(functions.httpsCallable('editSignalGroupConnector'), {
      groupId,
      connectorId,
      //connector values from form
      connectorData,
    })
    yield put(actions.editGroupConnectorSuccess(groupId, connectorId))
    onSuccess()
  } catch (e) {
    yield put(actions.editGroupConnectorFailure(e))
    onError(e)
  }
}
function* deleteGroupConnectorSaga({
  groupId,
  connectorId,
  onSuccess,
  onError,
}) {
  try {
    const connectorRef = firebase
      .firestore()
      .collection('signalGroups')
      .doc(groupId)
      .collection('connectors')
      .doc(connectorId)
    yield call(rsf.firestore.deleteDocument, connectorRef)
    yield put(actions.deleteGroupConnectorSuccess(groupId, connectorId))
    onSuccess()
  } catch (e) {
    yield put(actions.deleteGroupConnectorFailure(e))
    onError(e.message)
  }
}

/**
 * ANALYTICS
 */
//GROUP
const logGroupEvent = (eventName, groupId) => {
  analytics.logEvent(eventName, {
    group_id: groupId,
  })
}
const logCreateSignalGroupSuccess = ({ gid }) =>
  logGroupEvent('create_group', gid)

//CONNECTORS
const logConnectorEvent = (eventName, groupId, connectorId) =>
  analytics.logEvent(`${eventName}_connector`, {
    group_id: groupId,
    connector_id: connectorId,
  })
const logCreateGroupConnectorSuccess = ({ groupId, connectorId }) =>
  logConnectorEvent('create', groupId, connectorId)

const logEditGroupConnectorSuccess = ({ groupId, connectorId }) =>
  logConnectorEvent('update', groupId, connectorId)

const logDeleteGroupConnectorSuccess = ({ groupId, connectorId }) =>
  logConnectorEvent('delete', groupId, connectorId)

//MEMBERSHIP
const logMembershipEvent = (eventName, groupId, usersData) =>
  analytics.logEvent(eventName, {
    group_id: groupId,
    user_ids: usersData.map((user) => user.id),
  })
const logApproveMembershipRequestsSuccess = ({ groupId, usersData }) =>
  logMembershipEvent('membership_approved', groupId, usersData)

const logRevokeMembersSuccess = ({ groupId, usersIds }) =>
  logMembershipEvent('membership_revoked', groupId, usersIds)

const logRejectMembershipRequestsSuccess = ({ groupId, usersIds }) =>
  logMembershipEvent('membership_rejected', groupId, usersIds)

const logGroupMembershipChange = (change, groupId) =>
  logGroupEvent(`${change}_group`, groupId)
function logSubscribeUserSuccess({ group }) {
  logGroupMembershipChange('join_public', group.id)
}
const logUnsubscribeUserSuccess = ({ gid }) => {
  logGroupMembershipChange('leave_public', gid)
}
const logRequestAccessSuccess = ({ gid }) =>
  logGroupMembershipChange('request_private', gid)

const logRevokeAccessSuccess = ({ gid }) =>
  logGroupMembershipChange('leave_private', gid)

export function* saga() {
  yield all([
    takeLatest(actionTypes.SIGNAL_GROUPS.CREATE, createSignalGroupSaga),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.CREATE_SUCCESS,
      logCreateSignalGroupSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.APPROVE_MEMBERSHIP_REQUESTS_REQUEST,
      approveMembershipRequestsSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.APPROVE_MEMBERSHIP_REQUESTS_SUCCESS,
      logApproveMembershipRequestsSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REJECT_MEMBERSHIP_REQUESTS_REQUEST,
      removeMembershipRequestsSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REJECT_MEMBERSHIP_REQUESTS_SUCCESS,
      logRejectMembershipRequestsSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REMOVE_MEMBERS_REQUEST,
      removeMembersSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REMOVE_MEMBERS_SUCCESS,
      logRevokeMembersSuccess
    ),
    // takeLatest(authActionTypes.USER_DATA_COMPLETE, syncSignalGroupsSaga),
    takeLatest(actionTypes.SIGNAL_GROUPS.SYNC_REQUEST, syncSignalGroupsSaga),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.SYNC_SUCCESS,
      syncSignalGroupSecretsSaga
    ),
    // takeEvery(actionTypes.SIGNAL_GROUPS.DELETE, deleteSignalGroup),

    //public
    takeLatest(
      actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_REQUEST,
      subscribeUserSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.SUBSCRIBE_USER_SUCCESS,
      logSubscribeUserSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.UNSUBSCRIBE_USER_REQUEST,
      unsubscribeUserSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.UNSUBSCRIBE_USER_SUCCESS,
      logUnsubscribeUserSuccess
    ),

    //private
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_REQUEST,
      requestAccessSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REQUEST_ACCESS_SUCCESS,
      logRequestAccessSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_REQUEST,
      revokeAccessSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUPS.REVOKE_ACCESS_REQUEST,
      logRevokeAccessSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_REQUEST,
      createGroupConnectorSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUP_SECRETS.CREATE_GROUP_CONNECTOR_SUCCESS,
      logCreateGroupConnectorSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_REQUEST,
      editGroupConnectorSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUP_SECRETS.EDIT_GROUP_CONNECTOR_SUCCESS,
      logEditGroupConnectorSuccess
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_REQUEST,
      deleteGroupConnectorSaga
    ),
    takeLatest(
      actionTypes.SIGNAL_GROUP_SECRETS.DELETE_GROUP_CONNECTOR_SUCCESS,
      logDeleteGroupConnectorSuccess
    ),
  ])
}
