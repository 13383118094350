import {
  all,
  takeLatest,
  call,
  select,
  fork,
  put,
  take,
  cancel,
} from 'redux-saga/effects'
import firebase, { analytics, db, functions } from '../../services/firebase'
import { authActionTypes } from './auth.duck'
import { FirestoreHelpers } from '@tad3j/shared'
import ReduxSagaFirebase from 'redux-saga-firebase'
import { set } from 'lodash/fp'
const rsf = new ReduxSagaFirebase(firebase)

export const actionTypes = {
  SYNC_REQUEST: 'INTEGRATIONS.SYNC_REQUEST',
  SYNC_SUCCESS: 'INTEGRATIONS.SYNC_SUCCESS',
  SYNC_FAILURE: 'INTEGRATIONS.SYNC_FAILURE',
  STORE_INTEGRATION_REQUEST: 'INTEGRATIONS.STORE_INTEGRATION_REQUEST',
  STORE_INTEGRATION_SUCCESS: 'INTEGRATIONS.STORE_INTEGRATION_SUCCESS',
  STORE_INTEGRATION_FAILURE: 'INTEGRATIONS.STORE_INTEGRATION_FAILURE',
  SYNC_INTEGRATION_REQUEST: 'INTEGRATIONS.SYNC_INTEGRATION_REQUEST',
  SYNC_INTEGRATION_SUCCESS: 'INTEGRATIONS.SYNC_INTEGRATION_SUCCESS',
  SYNC_INTEGRATION_FAILURE: 'INTEGRATIONS.SYNC_INTEGRATION_FAILURE',
  EDIT_INTEGRATION_REQUEST: 'INTEGRATIONS.EDIT_INTEGRATION_REQUEST',
  EDIT_INTEGRATION_SUCCESS: 'INTEGRATIONS.EDIT_INTEGRATION_SUCCESS',
  EDIT_INTEGRATION_FAILURE: 'INTEGRATIONS.EDIT_INTEGRATION_FAILURE',
  DELETE_INTEGRATION_REQUEST: 'INTEGRATIONS.DELETE_INTEGRATION_REQUEST',
  DELETE_INTEGRATION_SUCCESS: 'INTEGRATIONS.DELETE_INTEGRATION_SUCCESS',
  DELETE_INTEGRATION_FAILURE: 'INTEGRATIONS.DELETE_INTEGRATION_FAILURE',
}

const initialState = {
  list: [],
}

export const reducer = function (state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SYNC_SUCCESS:
      return set('list', action.integrations, state)
    //we don't get all data, just id, so we do nothing
    // case actionTypes.STORE_INTEGRATION_SUCCESS:
    //   //check for duplicate!!!!
    //   return update(
    //     'list',
    //     (integrations) => [action.integration, ...integrations],
    //     state
    //   )
    default:
      return state
  }
}

export const actions = {
  syncIntegrationsRequest: () => ({
    type: actionTypes.SYNC_REQUEST,
  }),
  syncIntegrationsSuccess: (integrations) => ({
    type: actionTypes.SYNC_SUCCESS,
    integrations,
  }),
  syncIntegrationsFailure: (error) => ({
    type: actionTypes.SYNC_FAILURE,
    error,
  }),
  storeIntegration: (
    uid,
    integrationType,
    integrationData,
    onSuccess,
    onError
  ) => ({
    type: actionTypes.STORE_INTEGRATION_REQUEST,
    uid,
    integrationType,
    integrationData,
    onSuccess,
    onError,
  }),
  storeIntegrationSuccess: (userId, integrationId) => ({
    type: actionTypes.STORE_INTEGRATION_SUCCESS,
    userId,
    integrationId,
  }),
  storeIntegrationFailure: (error) => ({
    type: actionTypes.STORE_INTEGRATION_FAILURE,
    error,
  }),
  syncIntegration: (
    integrationType,
    uid,
    integrationId,
    onSuccess,
    onError
  ) => ({
    type: actionTypes.SYNC_INTEGRATION_REQUEST,
    integrationType,
    uid,
    integrationId,
    onSuccess,
    onError,
  }),
  syncIntegrationSuccess: (integration, uid) => ({
    type: actionTypes.SYNC_INTEGRATION_SUCCESS,
    integration,
    uid,
  }),
  syncIntegrationFailure: (error) => ({
    type: actionTypes.SYNC_INTEGRATION_FAILURE,
    error,
  }),
  editIntegration: (
    userId,
    integrationId,
    integrationData,
    onSuccess,
    onError
  ) => ({
    type: actionTypes.EDIT_INTEGRATION_REQUEST,
    userId,
    integrationId,
    integrationData,
    onSuccess,
    onError,
  }),
  editIntegrationSuccess: (userId, integrationId) => ({
    type: actionTypes.EDIT_INTEGRATION_SUCCESS,
    userId,
    integrationId,
  }),
  editIntegrationFailure: (error) => ({
    type: actionTypes.EDIT_INTEGRATION_FAILURE,
    error,
  }),
  deleteIntegration: (userId, integrationId, onSuccess, onError) => ({
    type: actionTypes.DELETE_INTEGRATION_REQUEST,
    userId,
    integrationId,
    onSuccess,
    onError,
  }),
  deleteIntegrationSuccess: (userId, integrationId) => ({
    type: actionTypes.DELETE_INTEGRATION_SUCCESS,
    userId,
    integrationId,
  }),
  deleteIntegrationFailure: (error) => ({
    type: actionTypes.DELETE_INTEGRATION_FAILURE,
    error,
  }),
}

function* storeIntegrationSaga(action) {
  try {
    //call function with object containing value
    // const { data } =
    const { data } = yield call(functions.httpsCallable('addIntegration'), {
      userId: action.uid,
      type: action.integrationType,
      integration: action.integrationData,
    })
    //prepare data for UI
    // const now = firebase.firestore.Timestamp.now().toMillis()
    // const { endpoint, username, password, ...rest } = action.payload
    // const integrationData = {
    //   ...rest,
    //   id: data.id, //only data we get from backend
    //   client: { endpoint, username, password },
    //   owner: action.uid,
    //   createdAt: now,
    //   updatedAt: now,
    // }
    yield put(actions.storeIntegrationSuccess(action.uid, data.id))
    action.onSuccess()
  } catch (e) {
    yield put(actions.storeIntegrationFailure(e))
    action.onError(e)
  }
}
function* syncIntegrationSaga({
  integrationType,
  uid,
  integrationId,
  onSuccess,
  onError,
}) {
  try {
    //call function with object containing value
    // const { data } =
    yield call(functions.httpsCallable('syncIntegration'), {
      type: integrationType,
      userId: uid,
      integrationId,
    })
    yield put(actions.syncIntegrationSuccess())
    onSuccess()
  } catch (e) {
    yield put(actions.syncIntegrationFailure(e))
    onError(e)
  }
}

function* editIntegrationSaga(action) {
  try {
    //call function with object containing value
    const userId = action.userId
    const integrationId = action.integrationId
    const integrationData = action.integrationData
    yield call(functions.httpsCallable('editIntegration'), {
      userId,
      integrationId,
      integrationData,
    })
    //prepare data for UI
    // const now = firebase.firestore.Timestamp.now().toMillis()
    // const { endpoint, username, password, ...rest } = action.payload
    // const integrationData = {
    //   ...rest,
    //   id: data.id, //only data we get from backend
    //   client: { endpoint, username, password },
    //   owner: action.uid,
    //   createdAt: now,
    //   updatedAt: now,
    // }
    yield put(actions.editIntegrationSuccess(userId, integrationId))
    action.onSuccess()
  } catch (e) {
    yield put(actions.editIntegrationFailure(e))
    action.onError(e)
  }
}

function* deleteIntegrationSaga(action) {
  try {
    const userId = action.userId
    const integrationId = action.integrationId
    const integrationRef = db
      .collection('users')
      .doc(userId)
      .collection('integrations')
      .doc(integrationId)
    yield call(rsf.firestore.deleteDocument, integrationRef)
    yield put(actions.deleteIntegrationSuccess(userId, integrationId))
    action.onSuccess()
  } catch (e) {
    yield put(actions.deleteIntegrationFailure(e))
    action.onError(e)
  }
}

function* syncIntegrationsSaga(/*action*/) {
  const { uid } = yield select((state) => state.auth.user)
  const { isAdmin, isPublisher } = yield select((state) => state.auth)
  const isAdminOrPublisher = isAdmin || isPublisher
  //only sync users for admin, else return empty array
  let syncIntegrationsTask
  if (isAdminOrPublisher) {
    /**
     * @type {firebase.firestore.CollectionReference<firebase.firestore.DocumentData>}
     */
    const colRef = db.collection('users').doc(uid).collection('integrations')

    // Start the sync task
    syncIntegrationsTask = yield fork(rsf.firestore.syncCollection, colRef, {
      transform: FirestoreHelpers.receiveIntegrations,
      successActionCreator: actions.syncIntegrationsSuccess,
      failureActionCreator: actions.syncIntegrationsFailure,
    })
  } else {
    yield put(actions.syncIntegrationsSuccess([]))
  }

  // Wait for the logout action, then stop sync
  yield take(authActionTypes.LOGOUT_SUCCESS)

  //cancel sync task if it was started
  if (syncIntegrationsTask) {
    yield cancel(syncIntegrationsTask)
  }
}

/**
 * ANALYTICS
 */
const logIntegrationEvent = (eventName, userId, integrationId) =>
  analytics.logEvent(`${eventName}_integration`, {
    user_id: userId,
    integration_id: integrationId,
  })

function* logStoreIntegrationSuccess(action) {
  yield logIntegrationEvent('create', action.userId, action.integrationId)
}

function* logEditIntegrationSuccess(action) {
  yield logIntegrationEvent('update', action.userId, action.integrationId)
}

function* logDeleteIntegrationSuccess(action) {
  yield logIntegrationEvent('delete', action.userId, action.integrationId)
}

export function* saga() {
  yield all([
    takeLatest(
      [actionTypes.SYNC_REQUEST, authActionTypes.USER_DATA_COMPLETE],
      syncIntegrationsSaga
    ),
    takeLatest(actionTypes.STORE_INTEGRATION_REQUEST, storeIntegrationSaga),
    takeLatest(
      actionTypes.STORE_INTEGRATION_SUCCESS,
      logStoreIntegrationSuccess
    ),
    takeLatest(actionTypes.SYNC_INTEGRATION_REQUEST, syncIntegrationSaga),
    takeLatest(actionTypes.EDIT_INTEGRATION_REQUEST, editIntegrationSaga),
    takeLatest(actionTypes.EDIT_INTEGRATION_SUCCESS, logEditIntegrationSuccess),
    takeLatest(actionTypes.DELETE_INTEGRATION_REQUEST, deleteIntegrationSaga),
    takeLatest(
      actionTypes.DELETE_INTEGRATION_SUCCESS,
      logDeleteIntegrationSuccess
    ),
  ])
}
