const enMessages = require('./messages/en')
const deMessages = require('./messages/de')
const esMessages = require('./messages/es')
const frMessages = require('./messages/fr')
const jaMessages = require('./messages/ja')
const zhMessages = require('./messages/zh')

export default {
  de: deMessages,
  en: enMessages,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
}
