import * as yup from 'yup'
import { email } from './common'
import FieldNames from '../fields'

export const schemaSpec = {
  [FieldNames.EMAIL]: email(),
}
export default function validationSchema() {
  return yup.object(schemaSpec)
}
