import {
  all,
  call,
  fork,
  select,
  take,
  takeLatest,
  cancel,
  put,
} from 'redux-saga/effects'
import firebase, { analytics, functions } from '../../services/firebase'
import ReduxSagaFirebase from 'redux-saga-firebase'
import { FirestoreHelpers, SIGNAL_STATUS, ReduxSelectors } from '@tad3j/shared'
import { authActionTypes } from './auth.duck'

const rsf = new ReduxSagaFirebase(firebase)

export const actionTypes = {
  SIGNALS: {
    CREATE: 'SIGNALS.CREATE',
    CREATE_SUCCESS: 'SIGNALS.CREATE_SUCCESS',
    CREATE_FAILURE: 'SIGNALS.CREATE_FAILURE',
    SYNC_REQUEST: 'SIGNALS.SYNC_REQUEST',
    SYNC_SUCCESS: 'SIGNALS.SYNC_SUCCESS',
    SYNC_FAILURE: 'SIGNALS.SYNC_FAILURE',
    DELETE: 'SIGNALS.DELETE',
    UPDATE: 'SIGNALS.UPDATE',
    UPDATE_SUCCESS: 'SIGNALS.UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SIGNALS.UPDATE_FAILURE',
    CLOSE: 'SIGNALS.CLOSE',
    CLOSE_SUCCESS: 'SIGNALS.CLOSE_SUCCESS',
    CLOSE_FAILURE: 'SIGNALS.CLOSE_FAILURE',
    GET_SYMBOLS: 'SIGNALS.GET_SYMBOLS',
    GET_SYMBOLS_SUCCESS: 'SIGNALS.GET_SYMBOLS_SUCCESS',
  },
}

const initialState = {
  list: [],
  newSignal: undefined,
  symbolOptions: [],
}

function createSignalData(newSignal, uid, now) {
  return {
    ...newSignal,
    owner: uid,
    status: SIGNAL_STATUS.LIVE,
    createdAt: now,
    updatedAt: now,
  }
}
function getCloseData(values, now) {
  const { pl, ...rest } = values
  const data = {
    ...rest,
    status: SIGNAL_STATUS.CLOSED,
    updatedAt: now,
  }
  data.closed.at = now
  //pl is optional so only add it if it's not an empty string (formik/yup pass this instead of null or undefined)
  if (pl !== '') {
    data.pl = pl
  }

  return data
}
export const reducer = function (state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SIGNALS.CREATE:
      const now = firebase.firestore.Timestamp.now().toMillis()
      const newSignal = createSignalData(action.newSignal, action.uid, now)
      let newList = state.list
      newList.unshift(newSignal)
      return {
        ...state,
        newSignal: newSignal,
        list: newList, //add new signal to the top of the array
      }
    case actionTypes.SIGNALS.UPDATE:
      return {
        ...state,
        list: state.list.map((signal) => {
          if (signal.id !== action.signalId) {
            return signal
          }

          return {
            ...signal,
            //override signal with values
            ...action.values,
            updatedAt: firebase.firestore.Timestamp.now().toMillis(),
          }
        }),
      }
    case actionTypes.SIGNALS.CLOSE:
      return {
        ...state,
        list: state.list.map((signal) => {
          if (signal.id !== action.signalId) {
            return signal
          }
          return {
            ...signal,
            ...getCloseData(
              action.values,
              firebase.firestore.Timestamp.now().toMillis()
            ),
          }
        }),
      }
    case actionTypes.SIGNALS.SYNC_SUCCESS:
      return {
        ...state,
        list: action.signals,
      }
    case actionTypes.SIGNALS.GET_SYMBOLS:
      return {
        ...state,
        symbolOptions: [],
      }
    case actionTypes.SIGNALS.GET_SYMBOLS_SUCCESS:
      return {
        ...state,
        symbolOptions: action.symbols,
      }
    // case actionTypes.SIGNALS.DELETE:
    //     return {
    //         ...state,
    //         signals: state.list.filter(signal => signal.id !== action.signalId ),
    //     };
    default:
      return state
  }
}

export const actions = {
  createSignal: (newSignal, uid, onSuccess, onError) => ({
    type: actionTypes.SIGNALS.CREATE,
    newSignal,
    uid,
    onSuccess,
    onError,
  }),
  createSignalSuccess: (signalId) => ({
    type: actionTypes.SIGNALS.CREATE_SUCCESS,
    signalId,
  }),
  createSignalsFailure: (error) => ({
    type: actionTypes.SIGNALS.CREATE_FAILURE,
    error,
  }),
  syncSignalsRequest: () => ({
    type: actionTypes.SIGNALS.SYNC_REQUEST,
  }),
  syncSignalsSuccess: (signals) => ({
    type: actionTypes.SIGNALS.SYNC_SUCCESS,
    signals,
  }),
  syncSignalsFailure: (error) => ({
    type: actionTypes.SIGNALS.SYNC_FAILURE,
    error,
  }),
  updateSignal: (signalId, values, onSuccess, onError) => ({
    type: actionTypes.SIGNALS.UPDATE,
    signalId,
    values,
    onSuccess,
    onError,
  }),
  updateSignalSuccess: (signalId) => ({
    type: actionTypes.SIGNALS.UPDATE_SUCCESS,
    signalId,
  }),
  updateSignalFailure: (error) => ({
    type: actionTypes.SIGNALS.UPDATE_FAILURE,
    error,
  }),
  closeSignal: (signalId, values, onSuccess, onError) => ({
    type: actionTypes.SIGNALS.CLOSE,
    signalId,
    values,
    onSuccess,
    onError,
  }),
  closeSignalSuccess: (signalId) => ({
    type: actionTypes.SIGNALS.CLOSE_SUCCESS,
    signalId,
  }),
  closeSignalFailure: (error) => ({
    type: actionTypes.SIGNALS.CLOSE_FAILURE,
    error,
  }),
  getSymbols: (value, onSuccess, onError) => ({
    type: actionTypes.SIGNALS.GET_SYMBOLS,
    value,
    onSuccess,
    onError,
  }),
  getSymbolsSuccess: (searchTerm, symbols) => ({
    type: actionTypes.SIGNALS.GET_SYMBOLS_SUCCESS,
    searchTerm,
    symbols,
  }),
}

function* createSignalSaga(action) {
  const now = firebase.firestore.Timestamp.now()
  try {
    const newSignal = createSignalData(action.newSignal, action.uid, now)
    const result = yield call(rsf.firestore.addDocument, 'signals', newSignal)
    const signalId = result.id
    // const signal = FirestoreHelpers.receiveSignal(result)
    action.onSuccess(signalId)
    yield put(actions.createSignalSuccess(signalId))
  } catch (e) {
    action.onError(e.message)
    yield put(actions.createSignalsFailure(e))
  }
}

function* closeSignalSaga(action) {
  try {
    const signalId = action.signalId
    const data = getCloseData(action.values, firebase.firestore.Timestamp.now())
    yield call(rsf.firestore.updateDocument, `signals/${signalId}`, data)
    action.onSuccess()
    yield put(actions.closeSignalSuccess(signalId))
  } catch (e) {
    console.error(e)
    action.onError(e.message)
    yield put(actions.closeSignalFailure(e))
  }
}

function* updateSignalSaga(action) {
  try {
    const signalId = action.signalId
    //for efficiency we don't take the updated signal from state, but rather just values used in update form (action.values)
    const data = {
      id: signalId,
      values: action.values,
    }
    const updateSignal = functions.httpsCallable('updateSignal')

    yield call(updateSignal, data)
    action.onSuccess()
    yield put(actions.updateSignalSuccess(signalId))
  } catch (e) {
    action.onError(e)
    yield put(actions.updateSignalFailure(e))
  }
}

function* syncSignalsSaga(/*action*/) {
  const { user, isAdmin, isPublisher } = yield select((state) => state.auth)
  const isAdminOrPublisher = isAdmin || isPublisher
  const userGroups = yield select(ReduxSelectors.SignalGroups.selectUserGroups)
  let colRef, syncSignalsTask
  if (isAdminOrPublisher) {
    colRef = firebase
      .firestore()
      .collection('signals')
      .where('owner', '==', user.uid)
      .orderBy('updatedAt', 'desc')
  } else {
    if (userGroups && userGroups.length > 0) {
      colRef = firebase
        .firestore()
        .collection('signals')
        .where('group.id', 'in', userGroups)
        .orderBy('updatedAt', 'desc')
    }
  }
  if (colRef) {
    // Start the sync task
    // noinspection JSCheckFunctionSignatures
    syncSignalsTask = yield fork(rsf.firestore.syncCollection, colRef, {
      transform: FirestoreHelpers.receiveSignals,
      successActionCreator: actions.syncSignalsSuccess,
      failureActionCreator: actions.syncSignalsFailure,
    })
  } else {
    yield put(actions.syncSignalsSuccess([]))
  }

  // Wait for the logout action, then stop sync
  yield take(authActionTypes.LOGOUT_SUCCESS)
  //cancel sync task
  if (syncSignalsTask) {
    yield cancel(syncSignalsTask)
  }
}

function* getSymbolsSage({ value, onSuccess, onError }) {
  try {
    //instead of calling call(searchAlgoliaForSymbols) we use firebase function so we don't have to expose API key
    const getTradingSymbols = functions.httpsCallable('getTradingSymbols')
    //call function with object containing value
    const results = yield call(getTradingSymbols, { value })
    //success
    yield put(actions.getSymbolsSuccess(value, results.data))
    onSuccess()
  } catch (e) {
    onError(e)
  }
}

// function* deleteSignal(action) {
//     yield call(rsf.firestore.deleteDocument, `signals/${action.signalId}`)
// }

/**
 * ANALYTICS
 */
const logGetSymbolsSuccess = ({ searchTerm }) =>
  analytics.logEvent('search_tv_symbol', {
    search_term: searchTerm,
  })
const logSignalEvent = (eventName, signalId) =>
  analytics.logEvent(`${eventName}_signal`, {
    signal_id: signalId,
  })
function* logCreateSignalSuccess(action) {
  yield logSignalEvent('create', action.signalId)
}
function* logUpdateSignalSuccess(action) {
  yield logSignalEvent('update', action.signalId)
}
function* logCloseSignalSuccess(action) {
  yield logSignalEvent('close', action.signalId)
}

export function* saga() {
  yield all([
    takeLatest(actionTypes.SIGNALS.CREATE, createSignalSaga),
    takeLatest(actionTypes.SIGNALS.CREATE_SUCCESS, logCreateSignalSuccess),
    takeLatest(actionTypes.SIGNALS.UPDATE, updateSignalSaga),
    takeLatest(actionTypes.SIGNALS.UPDATE_SUCCESS, logUpdateSignalSuccess),
    takeLatest(actionTypes.SIGNALS.CLOSE, closeSignalSaga),
    takeLatest(actionTypes.SIGNALS.CLOSE_SUCCESS, logCloseSignalSuccess),
    takeLatest(actionTypes.SIGNALS.GET_SYMBOLS, getSymbolsSage),
    takeLatest(actionTypes.SIGNALS.GET_SYMBOLS_SUCCESS, logGetSymbolsSuccess),
    // takeEvery(actionTypes.SIGNALS.DELETE, deleteSignal),
    takeLatest(actionTypes.SIGNALS.SYNC_REQUEST, syncSignalsSaga),
  ])
}
