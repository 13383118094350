const TV_WEBSITE = 'https://www.tradingview.com'
/**
 * Get TradingView symbol id (used for loading chart)
 * @param {*} symbol
 * @param {string} delimiter
 * @return {string}
 */
const getSymbolId = (symbol, delimiter = ':') =>
  `${symbol.prefix ? symbol.prefix : symbol.exchange}${delimiter}${
    symbol.symbol
  }`
/**
 * Returns TradingView URL for symbol details
 * @param {*} symbol
 * @return {string}
 */
const getSymbolDetailsUrl = (symbol) =>
  `${TV_WEBSITE}/symbols/${getSymbolId(symbol, '-')}/`

/**
 * Extract TradingView idea id from URL
 * @param url {string}
 * @returns {string|null|undefined}
 */
const getIdeaIdFromURL = (url) => {
  if (!url) {
    return undefined
  }
  const result = url.match(/(?!\/)([a-z]|[A-Z]|[0-9]){8,}(?=-)/g)
  if (!result) {
    return null
  }

  return result[0]
}

const timeIntervals = {
  M: '1 month',
  W: '1 week',
  D: '1 day',
  240: '4 hours',
  180: '3 hours',
  120: '2 hours',
  60: '1 hour',
  30: '30 minutes',
  15: '15 minutes',
  5: '5 minutes',
  3: '3 minutes',
  1: '1 minute',
}

export default {
  timeIntervals,
  getSymbolId,
  getSymbolDetailsUrl,
  getIdeaIdFromURL,
}
