import { update } from 'lodash/fp'

/**
 * Adds uid to a list (array) of members
 * @param {string} item
 * @param {[]} array
 * @returns {[]}
 */
const pushItemToArray = (item, array) => {
  if (array) {
    if (!array.includes(item)) {
      array.push(item)
    }
  } else {
    array = [item]
  }
  return array
}

/**
 * Removes removeItem from an array. If empty or undefined it just returns
 * @param {string} removeItem
 * @param {[]} array
 * @returns {[]}
 */
const removeItemFromArray = (removeItem, array) => {
  return array ? array.filter((item) => item !== removeItem) : array
}

/**
 * Removes key from object
 * @param {string} key
 * @param {*} object
 * @returns {*}
 */
const removeKeyFromObject = (key, object) => {
  if (object && key in object) {
    delete object[key]
  }
  return object
}

/**REDUX STATE REDUCER HELPERS*/
/**
 * Removes array items at "path" of "state" that match "removeValue"
 * @param {string} path
 * @param {string} removeValue
 * @param {Object} state
 * @returns {Object}
 */
const filterArrayItems = (path, removeValue, state) => {
  return update(
    path,
    (values) => values.filter((value) => value !== removeValue),
    state
  )
}

const capitalize = (s) => {
  if (typeof s !== 'string') {
    return ''
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

/**
 * Transforms email to user name
 * @param {string} email
 * @return {string}
 */
const getUserNameFromEmail = (email) =>
  email
    //remove characters that come after + or @  (test+1@gmail.com)
    .replace(/([@+]).*$/, '')
    //replace all remaining special characters except .
    .replace(/[^a-zA-Z^.]/g, '')
    //split string by . and use that as username parts
    .split('.')
    //capitalize first letter and join parts with blank space
    .map(capitalize)
    .join(' ')

/**
 * Returns sum of numbers in array
 * @param {[number]} numbers
 * @return number
 */
const sumArrayOfNumbers = (numbers) =>
  numbers.reduce((a, number) => a + number, 0)

const removeUriSchema = (uri) => uri.replace(/^.*?:\/\//, '')

export default {
  pushItemToArray,
  removeItemFromArray,
  removeKeyFromObject,
  filterArrayItems,
  getUserNameFromEmail,
  sumArrayOfNumbers,
  removeUriSchema,
}
