const getDiffKindWord = (kind) => {
  switch (kind) {
    case 'N': //new
      return 'Added'
    case 'E': //edited
      return 'Changed'
    case 'D': //deleted
      return 'Removed'
    default:
      return ''
  }
}
const getDiffArrayText = (fieldValues) => {
  return Object.keys(fieldValues)
    .map((fieldKey) => {
      return `${fieldKey}=${fieldValues[fieldKey]}`
    })
    .join(', ')
}

function convertArrayDiff(d) {
  const diffItem = d.item
  const kind = diffItem.kind
  const diffText = `${getDiffKindWord(kind)} ${d.path[0]
    .charAt(0)
    .toUpperCase()}x`
  switch (kind) {
    case 'N':
      return `${diffText} ${getDiffArrayText(diffItem.rhs)}`
    case 'D':
      return `${diffText} ${getDiffArrayText(diffItem.lhs)}`
    default:
      throw new Error(`Unknown Signal difference kind ${kind}.`)
  }
}

function convertDiff(d) {
  const keys = d.path
  const fieldName =
    keys.length === 3
      ? `${keys[0].charAt(0).toUpperCase()}${keys[1] + 1} ${
          keys[2].charAt(0).toUpperCase() + keys[2].slice(1)
        }`
      : keys[0]
  const diffText = `${getDiffKindWord(d.kind)} ${fieldName}`
  // if we have LHS this means it's an update, if LHS is undefined it was create
  return d.lhs !== undefined
    ? `${diffText} from ${d.lhs} to ${d.rhs}`
    : `${diffText} ${d.rhs}`
}

/**
 *
 * if object's array changed
 * @param {{lhs: string, rhs:string, kind: string, path: string, item: string}} d
 * @return {string}
 */
const convertToHumanDiff = (d) =>
  d.kind === 'A' ? convertArrayDiff(d) : convertDiff(d)

export default convertToHumanDiff
