/** QUERIES */

/**
 * Add pagination filter to query
 * @param query
 * @param pageSize
 * @param lastDocument
 * @return {any}
 */
const paginateQuery = (query, pageSize, lastDocument) =>
  lastDocument
    ? query.startAfter(lastDocument).limit(pageSize)
    : query.limit(pageSize)

/** CONVERTERS */
/**
 * Basic converter to extract data and id
 * @param doc
 * @return {*}
 */
const fromFirestore = (doc) => {
  return {
    ...doc.data(),
    id: doc.id,
  }
}
/**
 * Transform collection of documents to array of objects using `callback` to transform each
 * document to object
 * @param docs
 * @param {function(doc)} fromFirestoreCallback
 * @returns {[]}
 */
const fromFirestoreCollection = (docs, fromFirestoreCallback) => {
  const res = []
  docs.forEach((doc) => res.push(fromFirestoreCallback(doc)))
  return res
}

/**
 * @param data
 * @param {string} extraName
 * @return {{createdAt: number, updatedAt: number}}
 */
const convertModelTimestamps = (data, extraName = null) => {
  const values = {
    ...data,
  }
  if (data.createdAt) {
    values.createdAt = data.createdAt.toMillis()
  }
  if (data.updatedAt) {
    values.updatedAt = data.updatedAt.toMillis()
  }
  if (extraName && data[extraName]) {
    values[extraName] = data[extraName].toMillis()
  }
  return values
}
const convertArrayModelTimestamp = (array, name) =>
  array
    ? array.map((obj) => {
        obj[name] = obj[name].toMillis()
        return obj
      })
    : array

//USER
const receiveUser = (doc) => convertModelTimestamps(fromFirestore(doc))
const receiveUsers = (array) => fromFirestoreCollection(array, receiveUser)

//USER SECRETS
const receiveUserSecrets = (doc) => convertModelTimestamps(fromFirestore(doc))
const receiveUsersSecrets = (array) =>
  fromFirestoreCollection(array, receiveUserSecrets)

//USER INTEGRATIONS
const receiveIntegration = (doc) =>
  convertModelTimestamps(fromFirestore(doc), 'syncedAt')
const receiveIntegrations = (array) =>
  fromFirestoreCollection(array, receiveIntegration)
//INTEGRATIONS CLIENTS
const receiveIntegrationClient = (doc) =>
  convertModelTimestamps(fromFirestore(doc))
const receiveIntegrationClients = (array) =>
  fromFirestoreCollection(array, receiveIntegrationClient)

const convertSubObjectsTimestamps = (obj, name = 'createdAt') => {
  if (!obj) {
    return obj
  }
  Object.keys(obj).forEach((id) => {
    obj[id][name] = obj[id][name].toMillis()
  })
  return obj
}

//SIGNAL GROUPS
const receiveSignalGroup = (doc) => {
  const obj = fromFirestore(doc)
  return {
    ...convertModelTimestamps(obj),
    signalsLive: convertArrayModelTimestamp(obj.signalsLive, 'createdAt'),
  }
}
const receiveSignalGroups = (array) =>
  fromFirestoreCollection(array, receiveSignalGroup)

//SIGNAL GROUPS SECRETS
const receiveSignalGroupSecret = (doc) => {
  const obj = fromFirestore(doc)
  return {
    ...convertModelTimestamps(obj),
    id: obj.id,
    members: obj.members ? obj.members : [],
    membersDetails: obj.membersDetails
      ? convertSubObjectsTimestamps(obj.membersDetails)
      : {},
    devices: obj.devices
      ? convertSubObjectsTimestamps(obj.devices, 'updatedAt')
      : {},
    membershipRequests: obj.membershipRequests
      ? convertSubObjectsTimestamps(obj.membershipRequests)
      : {},
  }
}
const receiveSignalGroupSecrets = (array) =>
  fromFirestoreCollection(array, receiveSignalGroupSecret)

//SIGNAL GROUPS CONNECTORS
const receiveConnector = (doc) => {
  const connector = convertModelTimestamps(fromFirestore(doc))
  connector.integration = convertModelTimestamps(
    connector.integration,
    'syncedAt'
  )
  return connector
}
const receiveConnectors = (array) =>
  fromFirestoreCollection(array, receiveConnector)

//SIGNALS
const receiveSignal = (doc) => {
  const signal = fromFirestore(doc)
  return {
    ...convertModelTimestamps(signal),
    updates: signal.updates
      ? signal.updates.map((update) => ({
          ...update,
          updatedAt: update.updatedAt.toMillis(),
        }))
      : signal.updates,
    closed: signal.closed
      ? { ...signal.closed, at: signal.closed.at.toMillis() }
      : signal.closed,
  }
}
const receiveSignals = (array) => fromFirestoreCollection(array, receiveSignal)

export default {
  receiveUser,
  receiveUsers,
  receiveUserSecrets,
  receiveUsersSecrets,
  receiveIntegration,
  receiveIntegrations,
  receiveIntegrationClient,
  receiveIntegrationClients,
  receiveConnectors,
  receiveSignalGroup,
  receiveSignalGroups,
  receiveSignalGroupSecret,
  receiveSignalGroupSecrets,
  receiveSignal,
  receiveSignals,
  paginateQuery,
}
